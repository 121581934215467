import React, { useState, useEffect } from 'react';
import SideModal from './modals/SideModal';
import sanityClient from '../sanitySetup';
import PlanStatusCard from './PlanStatusCard';

const SavingDetails = ({
	openDetailsModal,
	setOpenDetailsModal,
	handleSelectedPlans,
	selectedDetails,
	plansSelected = '',
	isBtn = true,
	numberOfEmployee,
	remindEmployeeBenefit,
	showCardDetails = false,
}) => {
	const [post, setPost] = useState(null);
	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "savings"]{
			_id,
			_ref,
			name,
			bio,
			amount,
			frequency,
			interest,
		  }`
			)
			.then((data) => setPost(data))
			.catch(console.error);
	}, []);

	return (
		<SideModal
			btnText={'Choose Benefit'}
			handleClick={() => {
				handleSelectedPlans(selectedDetails);
				setOpenDetailsModal(false);
			}}
			headerText={post ? post[0].name : ''}
			openSideModal={openDetailsModal}
			setOpenSideModal={setOpenDetailsModal}
			isCart={false}
			btnDisbled={
				plansSelected.includes(selectedDetails?.benefitName) ? true : false
			}
			isBtn={isBtn}
		>
			{post && (
				<div className="benefit__details">
					{showCardDetails && (
						<div className="plan__status">
							<PlanStatusCard
								isRemider={false}
								numberOfPlan={selectedDetails?.activeEmployeeCount}
								status={'active'}
								statusName={'ACTIVE EMPLOYEES'}
								total={numberOfEmployee}
							/>
							<PlanStatusCard
								isRemider={true}
								numberOfPlan={selectedDetails?.inactiveEmployeeCount}
								status={'inactive'}
								statusName={'inACTIVE EMPLOYEES'}
								total={numberOfEmployee}
								remindEmployeeBenefit={() =>
									remindEmployeeBenefit(selectedDetails?.id)
								}
							/>
						</div>
					)}

					<div className="benefit__details--detail">
						<p>{post[0]?.bio}</p>
					</div>

					<div className="benefit__details--overview">
						<h2>Overwiew</h2>
						<div className="overview--container savings">
							<p>Frequency</p>
							<p className="country">{post[0]?.frequency}</p>
						</div>
						<div className="overview--container savings">
							<p>Interest</p>
							<p className="country">{post[0]?.interest}</p>
						</div>
						<div className="overview--container savings">
							<p>Amount</p>
							<p className="country">{post[0]?.amount}</p>
						</div>
					</div>
				</div>
			)}
		</SideModal>
	);
};

export default SavingDetails;
