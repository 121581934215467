import React from 'react';
import AuthStatus from '../../../components/AuthStatus';
import { useNavigate } from 'react-router-dom';

const ResetPawordMail = () => {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate('/auth/login');
	};
	return (
		<AuthStatus
			authDetails={
				'You’ve successfully reset your password. You can now login to access your benefits.'
			}
			authStatus={'Password reset successful'}
			btnText={'Back to Login'}
			icon={'check'}
			handleClick={handleClick}
		/>
	);
};

export default ResetPawordMail;
