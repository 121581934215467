import React from 'react';
import Input from '../../../components/forms/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserDetails } from '../../../features/user/userSlice';

const ContactInfo = () => {
	const { businessDetails, updateLoading } = useSelector((store) => store.user);
	const dispatch = useDispatch();
	const phoneRegExp =
		/((^090)([0-9]))|((^070)([0-9]))|((^080)([0-9]))|((^091)([0-9]))|((^071)([0-9]))|((^081)([0-9]))(\d{7})/;
	const schema = Yup.object({
		firstname: Yup.string().required('First name is required'),
		lastname: Yup.string().required('Last name is required'),
		phone: Yup.string()
			.required('Phone number is required')
			.matches(phoneRegExp, 'Phone number is not valid')
			.min(11, 'Phone number cannot be less than 11 characters')
			.max(14, 'Phone number cannot be longer than 14 characters'),
		email: Yup.string()
			.required('Email field is required')
			.email('Invalid email address'),
	});
	const { handleSubmit, handleChange, handleBlur, values } = useFormik({
		initialValues: {
			firstname: businessDetails?.businessContact?.firstName,
			lastname: businessDetails?.businessContact?.lastName,
			phone: businessDetails?.businessContact?.phoneNumber,
			email: businessDetails?.businessContact?.emailAddress,
		},
		validationSchema: schema,
		onSubmit: (values) => {
			dispatch(
				updateUserDetails({
					businessName: '',
					businessEmail: '',
					firstName:
						values.firstname.toLowerCase() !==
						businessDetails?.businessContact?.firstName.toLowerCase()
							? values.firstname
							: '',
					lastName:
						values.lastname.toLowerCase() !==
						businessDetails?.businessContact?.lastName.toLowerCase()
							? values.lastname
							: '',
					contactEmail:
						values.email.toLowerCase() !==
						businessDetails?.businessContact?.emailAddress.toLowerCase()
							? values.email
							: '',
					phoneNumber:
						values.phone !== businessDetails?.businessContact?.phoneNumber
							? values.phone
							: '',
					callBackUrl: '',
				})
			);
		},
	});
	return (
		<div className="settings__container">
			<div className="settings__container--header">
				<h2>Contact Info</h2>
				<p>
					Your point of contact can be your HR, Payroll Officer, Admin etc. It
					will help ease handover when the need arises.
				</p>
			</div>
			<div className="settings__container--body">
				<form
					className="__form __form-signup settings_form"
					onSubmit={handleSubmit}
				>
					<>
						{/* <div className="inputgroup">
							<Input
								type="text"
								id="firstname"
								label="First name"
								name="firstname"
								value={values.firstname}
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder="First name"
							/>
							<Input
								type="text"
								id="lastname"
								label="Last name"
								name="lastname"
								value={values.lastname}
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder="Last name"
							/>
						</div> */}
						<div className="inputgroup">
							<Input
								type="text"
								id="firstname"
								label="First name"
								name="firstname"
								value={values.firstname}
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder="First name"
								// error={
								// 	touched.firstname && errors.firstname ? (
								// 		<small className="error">{errors.firstname}</small>
								// 	) : null
								// }
							/>
							<Input
								type="text"
								id="lastname"
								label="Last name"
								name="lastname"
								value={values.lastname}
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder="Last name"
								// error={
								// 	touched.lastname && errors.lastname ? (
								// 		<small className="error">{errors.lastname}</small>
								// 	) : null
								// }
							/>
						</div>
						<Input
							type="email"
							id="email"
							label="Email address"
							name="email"
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
						<Input
							type="tel"
							id="phone"
							label="Phone number"
							name="phone"
							value={values.phone}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</>
					<p>
						If contact person is edited, an invite will be sent to the email to
						onboard the person.
					</p>
					<Button
						variant="primary"
						type="submit"
						className="mt-32"
						block
						disabled={updateLoading}
					>
						{updateLoading ? 'Loading...' : 'Update'}
					</Button>
				</form>
			</div>
		</div>
	);
};

export default ContactInfo;
