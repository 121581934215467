import React from 'react';
// import PropTypes from 'prop-types';
// import icons from 'assets/icon-sprite.svg';
import icons from '../assets/icon-sprite.svg';

function Icon({ width, height, id, className, onClick, size, disabled }) {
	return (
		<svg
			width={size ?? width}
			height={size ?? height}
			className={`${className ? className : ''} ${disabled ? 'opacity-7' : ''}`}
			onClick={onClick}
		>
			<use xlinkHref={`${icons}#${id}`} />
		</svg>
	);
}

// Icon.propTypes = {
// 	id: PropTypes.string,
// };

export default Icon;
