import React from 'react';
import AuthStatus from '../../../components/AuthStatus';
import { useDispatch } from 'react-redux';
import { resendUserVerification } from '../../../features/user/userSlice';
import { toast } from 'react-hot-toast';

const BrokenMail = () => {
	const dispatch = useDispatch();
	const handleClick = () => {
		const result = localStorage.getItem('businessMail');
		const user = result ? JSON.parse(result) : null;
		if (user) {
			dispatch(
				resendUserVerification({
					email: user,
					callBackUrl: `${window.location.origin}/auth/verifying-mail`,
				})
			);
		} else {
			notify('Email Not Found');
		}
	};
	const notify = (message) => toast.error(message);
	return (
		<AuthStatus
			authDetails={
				'Your verification link has expired. Please generate another link to verify your email.'
			}
			authStatus={'Broken email link'}
			btnText={'Regenerate Link'}
			icon={'broken'}
			handleClick={handleClick}
		/>
	);
};

export default BrokenMail;
