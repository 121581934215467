import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';
import { toast } from 'react-hot-toast';

const initialState = {
	isLoading: false,
	plans: [],
	createdPlan: null,
	createPlanLoading: false,
	planPrice: null,
	planPaymentLoading: false,
	paymentSuccess: false,
	isPlanActive: false,
	allTransactions: [],
	individualTransactions: null,
	planDetail: [],
	billingHistory: [],
	cardDetails: {},
};

const notify = (message) => toast.error(message);
const notifySuccess = (message) => toast.success(message);

export const getAllPlans = createAsyncThunk(
	'plans/allPlans',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.get('/benefit?page=0&size=1000');
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

export const createPlan = createAsyncThunk(
	'plans/createPlans',
	async (plan, thunkAPI) => {
		try {
			const resp = await customFetch.post('business/plan', plan);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

export const getPlanPrice = createAsyncThunk(
	'plans/planPrice',
	async (plan, thunkAPI) => {
		try {
			const resp = await customFetch.get(
				`business/purchase/total-price?planId=${plan?.id}&duration=${plan?.duration}&numberOfBeneficiary=${plan?.numberOfEmployee}&planType=${plan?.planType}`
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

export const planPayment = createAsyncThunk(
	'plans/planPayment',
	async (plan, thunkAPI) => {
		try {
			const resp = await customFetch.post('business/plan/purchase', plan);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

export const planPaymentForExisitingEmployee = createAsyncThunk(
	'plans/planPaymentForExisitingEmployee',
	async (plan, thunkAPI) => {
		try {
			const resp = await customFetch.post(
				'business/plan/purchase/employee',
				plan
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const benefitPaymentForExisitingPlan = createAsyncThunk(
	'plans/benefitPaymentForExisitingPlan',
	async (plan, thunkAPI) => {
		try {
			const resp = await customFetch.post(
				'business/plan/benefit/purchase',
				plan
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

export const planPaymentWithToken = createAsyncThunk(
	'plans/planPaymentWithToken',
	async (plan, thunkAPI) => {
		try {
			const resp = await customFetch.post(
				'business/plan/purchase/withToken',
				plan
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const planRenewal = createAsyncThunk(
	'plans/renewal',
	async (plan, thunkAPI) => {
		try {
			const resp = await customFetch.post('business/plan/renewal', plan);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const planRenewalWithToken = createAsyncThunk(
	'plans/planRenewalWithToken',
	async (plan, thunkAPI) => {
		try {
			const resp = await customFetch.post(
				'business/plan/renewal/withToken',
				plan
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

export const planPaymentConfirmation = createAsyncThunk(
	'plans/planPaymentConfirmation',
	async (plan, thunkAPI) => {
		try {
			const resp = await customFetch.post(`business/purchase/process/fw`, plan);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

export const planPaymentTransactions = createAsyncThunk(
	'plans/planPaymentTransactions',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.get(`business/plans?page=0&size=100`);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

export const getIndividualTransaction = createAsyncThunk(
	'plans/getIndividualTransaction',
	async (id, thunkAPI) => {
		try {
			const resp = await customFetch.get(`business/plans/${id}/employees`);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const getBusinessCardDetails = createAsyncThunk(
	'plans/getBusinessCardDetails',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.get(`business/payment/option`);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const activateBusinessCard = createAsyncThunk(
	'plans/activateBusinessCard',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.put(`business/payment/option/activate`);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const deactivateBusinessCard = createAsyncThunk(
	'plans/deactivateBusinessCard',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.put(`business/payment/option/deactivate`);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

export const getBillingHistory = createAsyncThunk(
	'plans/getBillingHistory',
	async (id, thunkAPI) => {
		try {
			const resp = await customFetch.get(
				`business/purchase/plans/${id}/billings?page=0&size=10`
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const removeEmployeeFromPlan = createAsyncThunk(
	'plans/removeEmployeeFromPlan',
	async (id, thunkAPI) => {
		try {
			const resp = await customFetch.delete(
				`business/plan/employee?employeeId=${id.employeeId}&planId=${id.planId}`
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const remindEmployeeActivateBenefit = createAsyncThunk(
	'plans/remindEmployeeActivateBenefit',
	async (payload, thunkAPI) => {
		try {
			const resp = await customFetch.post(
				`business/plan/benefit/inactive/notification`,
				payload
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

const planSlice = createSlice({
	name: 'plan',
	initialState,
	reducers: {
		clearPlanState: (state) => {
			state.createdPlan = null;
		},
		setPlanActiveTrue: (state) => {
			state.isPlanActive = true;
		},
		setPaymentFlase: (state) => {
			state.paymentSuccess = false;
		},
		addPlanDetails: (state, { payload }) => {
			state.planDetail = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllPlans.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getAllPlans.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.plans = payload?.content;
			})
			.addCase(getAllPlans.rejected, (state, { payload }) => {
				state.isLoading = false;
				notify(payload);
			})
			.addCase(createPlan.pending, (state) => {
				state.createPlanLoading = true;
			})
			.addCase(createPlan.fulfilled, (state, { payload }) => {
				state.createPlanLoading = false;
				state.createdPlan = payload;
			})
			.addCase(createPlan.rejected, (state, { payload }) => {
				state.createPlanLoading = false;
				notify(payload);
			})
			.addCase(getPlanPrice.pending, (state) => {
				state.createPlanLoading = true;
			})
			.addCase(getPlanPrice.fulfilled, (state, { payload }) => {
				state.createPlanLoading = false;
				state.planPrice = payload;
			})
			.addCase(getPlanPrice.rejected, (state, { payload }) => {
				state.createPlanLoading = false;
				notify(payload);
			})
			.addCase(planPayment.pending, (state) => {
				state.planPaymentLoading = true;
			})
			.addCase(planPayment.fulfilled, (state, { payload }) => {
				state.planPaymentLoading = false;
				window.location = payload?.data?.link;
			})
			.addCase(planPayment.rejected, (state, { payload }) => {
				state.planPaymentLoading = false;
				notify(payload);
			})
			.addCase(planPaymentForExisitingEmployee.pending, (state) => {
				state.planPaymentLoading = true;
			})
			.addCase(
				planPaymentForExisitingEmployee.fulfilled,
				(state, { payload }) => {
					state.planPaymentLoading = false;
					window.location = payload?.data?.link;
				}
			)
			.addCase(
				planPaymentForExisitingEmployee.rejected,
				(state, { payload }) => {
					state.planPaymentLoading = false;
					notify(payload);
				}
			)
			.addCase(benefitPaymentForExisitingPlan.pending, (state) => {
				state.planPaymentLoading = true;
			})
			.addCase(
				benefitPaymentForExisitingPlan.fulfilled,
				(state, { payload }) => {
					state.planPaymentLoading = false;
					window.location = payload?.data?.link;
				}
			)
			.addCase(
				benefitPaymentForExisitingPlan.rejected,
				(state, { payload }) => {
					state.planPaymentLoading = false;
					notify(payload);
				}
			)
			.addCase(planPaymentWithToken.pending, (state) => {
				state.planPaymentLoading = true;
			})
			.addCase(planPaymentWithToken.fulfilled, (state) => {
				state.planPaymentLoading = false;
			})
			.addCase(planPaymentWithToken.rejected, (state, { payload }) => {
				state.planPaymentLoading = false;
				notify(payload);
			})
			.addCase(planRenewalWithToken.pending, (state) => {
				state.planPaymentLoading = true;
			})
			.addCase(planRenewalWithToken.fulfilled, (state) => {
				state.planPaymentLoading = false;
			})
			.addCase(planRenewalWithToken.rejected, (state, { payload }) => {
				state.planPaymentLoading = false;
				notify(payload);
			})
			.addCase(planRenewal.pending, (state) => {
				state.planPaymentLoading = true;
			})
			.addCase(planRenewal.fulfilled, (state, { payload }) => {
				state.planPaymentLoading = false;
				window.location = payload?.data?.link;
			})
			.addCase(planRenewal.rejected, (state, { payload }) => {
				state.planPaymentLoading = false;
				notify(payload);
			})
			.addCase(planPaymentConfirmation.pending, (state) => {
				state.planPaymentLoading = true;
			})
			.addCase(planPaymentConfirmation.fulfilled, (state) => {
				state.planPaymentLoading = false;
				state.paymentSuccess = true;
			})
			.addCase(planPaymentConfirmation.rejected, (state, { payload }) => {
				state.planPaymentLoading = false;
				notify(payload);
			})
			.addCase(activateBusinessCard.pending, (state) => {
				state.planPaymentLoading = true;
			})
			.addCase(activateBusinessCard.fulfilled, (state) => {
				state.planPaymentLoading = false;
			})
			.addCase(activateBusinessCard.rejected, (state, { payload }) => {
				state.planPaymentLoading = false;
				notify(payload);
			})
			.addCase(deactivateBusinessCard.pending, (state) => {
				state.planPaymentLoading = true;
			})
			.addCase(deactivateBusinessCard.fulfilled, (state) => {
				state.planPaymentLoading = false;
			})
			.addCase(deactivateBusinessCard.rejected, (state, { payload }) => {
				state.planPaymentLoading = false;
				notify(payload);
			})
			.addCase(planPaymentTransactions.pending, (state) => {
				state.planPaymentLoading = true;
			})
			.addCase(planPaymentTransactions.fulfilled, (state, { payload }) => {
				state.planPaymentLoading = false;
				state.allTransactions = payload;
			})
			.addCase(planPaymentTransactions.rejected, (state, { payload }) => {
				state.planPaymentLoading = false;
				notify(payload);
			})
			.addCase(getBusinessCardDetails.pending, (state) => {
				state.planPaymentLoading = true;
			})
			.addCase(getBusinessCardDetails.fulfilled, (state, { payload }) => {
				state.planPaymentLoading = false;
				state.cardDetails = payload;
			})
			.addCase(getBusinessCardDetails.rejected, (state) => {
				state.planPaymentLoading = false;
				// notify(payload);
			})
			.addCase(getIndividualTransaction.pending, (state) => {
				state.planPaymentLoading = true;
			})
			.addCase(getIndividualTransaction.fulfilled, (state, { payload }) => {
				state.planPaymentLoading = false;
				state.individualTransactions = payload?.data;
			})
			.addCase(getIndividualTransaction.rejected, (state, { payload }) => {
				state.planPaymentLoading = false;
				notify(payload);
			})
			.addCase(getBillingHistory.pending, (state) => {
				state.planPaymentLoading = true;
			})
			.addCase(getBillingHistory.fulfilled, (state, { payload }) => {
				state.planPaymentLoading = false;
				state.billingHistory = payload?.data;
			})
			.addCase(getBillingHistory.rejected, (state, { payload }) => {
				state.planPaymentLoading = false;
				notify(payload);
			})
			.addCase(removeEmployeeFromPlan.pending, (state) => {
				state.planPaymentLoading = true;
			})
			.addCase(removeEmployeeFromPlan.fulfilled, (state, { payload }) => {
				state.planPaymentLoading = false;
				notifySuccess(payload?.message);
			})
			.addCase(removeEmployeeFromPlan.rejected, (state, { payload }) => {
				state.planPaymentLoading = false;
				notify(payload);
			})
			.addCase(remindEmployeeActivateBenefit.pending, (state) => {
				state.createPlanLoading = true;
			})
			.addCase(remindEmployeeActivateBenefit.fulfilled, (state) => {
				state.createPlanLoading = false;
			})
			.addCase(remindEmployeeActivateBenefit.rejected, (state, { payload }) => {
				state.createPlanLoading = false;
				notify(payload);
			});
	},
});

export const {
	clearPlanState,
	setPlanActiveTrue,
	setPaymentFlase,
	addPlanDetails,
} = planSlice.actions;

export default planSlice.reducer;
