import React, { useEffect } from 'react';
import AuthStatus from '../../../components/AuthStatus';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const VerifyingMail = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const verifyEmail = async () => {
		const searchParams = location.search;
		const token = searchParams.split('?')[1];
		if (token) {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_BASE_URL}business/verify/email`,
					{},
					{
						headers: {
							Authorization: `Bearer ${token}`,
							Accept: 'application/json',
							'Content-Type': 'application/json',
						},
					}
				);
				if (response.status === 200) {
					notify();
					setTimeout(() => {
						navigate('/auth/login');
					}, 1000);
				}
			} catch (error) {
				notifyError();
				setTimeout(() => {
					navigate('/auth/broken-mail');
				}, 1000);
			}
		}
	};
	useEffect(() => {
		verifyEmail();
	}, [location.search]);
	const notify = () => toast.success('Email verification successful!!! 🎉🎉');
	const notifyError = () => toast.error('Email verification failed!!!');
	return (
		<>
			<div className="toaster__style">
				<Toaster position="top-right" reverseOrder={false} />
			</div>
			<AuthStatus
				authDetails={'Please wait, this action will take a few seconds'}
				authStatus={'Verifying your email'}
				icon={'mailbox'}
			/>
		</>
	);
};

export default VerifyingMail;
