import React from 'react';
import Table from './Table';
import { fmtCurrency } from '../../utils/helpers';

const BillingTable = ({ data }) => {
	const columns = ['Invoice', 'Date', 'Amount', 'Status'];
	return (
		<Table columns={columns}>
			{data?.map((row, index) => (
				<tr key={index}>
					<td className="plan-table ">{`${row?.invoiceDescription}`}</td>
					<td className="plan-table">{row?.transactionDate}</td>
					<td className="plan-table">{fmtCurrency(row?.amount)}</td>
					<td className="plan-table">
						<span
							className={`status ${
								row.status === 'SUCCESSFUL'
									? 'employee-active'
									: row?.status === 'inactive'
									? 'employee-inactive'
									: 'pending'
							}`}
						>
							{row?.status}
						</span>
					</td>
				</tr>
			))}
		</Table>
	);
};

export default BillingTable;
