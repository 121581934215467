import React, { useEffect, useState } from 'react';
import ActiveBenefits from '../../../components/ActiveBenefits';
import skeletonLoading from '../../../assets/svgs/chat-thread.svg';
import AddEmployeeModal from '../../../components/modals/AddEmployeeModal';
import ModalResponse from '../../../components/modals/ModalResponse';
import EmployeeActionModal from '../../../components/modals/EmployeeActionModal';
import EmployeesTable from '../../../components/table/EmployeesTable';
import SearchAddContainer from '../../../components/SearchAddContainer';
import Pagination from '../../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from '../../../features/employees/employeesSlice';
import DeleteEmployeeModal from '../../../components/modals/DeleteEmployeeModal';
import Spinner from '../../../components/PageLoader';
import { paginate } from '../../../utils/helpers';
import DeleteEmployeeSuccess from '../../../components/modals/DeleteEmployeeSuccess';
import MobileTable from '../../../components/modals/MobileTable';
import ConfirmExisitingUser from '../../../components/modals/ConfirmExisitingUser';

const Employees = () => {
	const [employeeSearch, setEmployeeSearch] = useState('');
	const [addEmployeeModal, setAddEmployeeModal] = useState(false);
	const [addEmployeeSuccess, setAddEmployeeSuccess] = useState(false);
	const [deactivateEmployeeModal, setDeactivateEmployeeModal] = useState(false);
	const [deleteEmployeeModal, setDeleteEmployeeModal] = useState(false);
	const [employeeDetails, setEmployeeDetails] = useState(false);
	const [paginatedEmployee, setPaginatedEmployee] = useState([]);
	const { employees, isLoading } = useSelector((store) => store.employees);
	const [currentEmployee, setCurrentEmployee] = useState();
	const [removeEmployee, setRemoveEmployee] = useState(false);
	const [exisitingUserModal, setExisitingUserModal] = useState(false);
	const [addEmployeeDetails, setAddEmployeeDetails] = useState({});
	const dispatch = useDispatch();

	const employeeFilter = employees.filter((user) => {
		if (
			user?.firstname?.toLowerCase().includes(employeeSearch.toLowerCase()) ||
			user?.lastname?.toLowerCase().includes(employeeSearch.toLowerCase())
		) {
			return user;
		}
	});

	useEffect(() => {
		if (employeeFilter) {
			setPaginatedEmployee(paginate(employeeFilter));
		}
	}, [employeeFilter?.length]);

	const columns = ['Employee name	', 'Email', 'Plan', 'Status', 'Actions'];

	const [currentEmployeeAction, setCurrentEmployeeAction] = useState(null);
	const handleEmployeeAction = (id) => {
		setCurrentEmployeeAction(id);
	};

	useEffect(() => {
		dispatch(getAllEmployees());
	}, []);

	const handleAddEmployees = () => {
		setAddEmployeeModal(true);
	};
	const [newEmployees, setNewEmployees] = useState([]);
	const [page, setPage] = useState(0);

	useEffect(() => {
		if (paginatedEmployee) {
			setNewEmployees(paginatedEmployee[page]);
		}
	}, [paginatedEmployee, page]);
	if (isLoading) {
		return <Spinner />;
	}
	return (
		<>
			{employees.length > 0 ? (
				<div className="employee">
					<SearchAddContainer
						searchInput={employeeSearch}
						handleCLick={() => setAddEmployeeModal(true)}
						changeInput={(e) => setEmployeeSearch(e.target.value)}
						btnTitle={'Add Employee'}
					/>
					<div className="employee__table">
						<EmployeesTable
							columns={columns}
							data={newEmployees}
							setDeactivateEmployeeModal={setDeactivateEmployeeModal}
							setDeleteEmployeeModal={setDeleteEmployeeModal}
							setCurrentEmployee={setCurrentEmployee}
						/>

						<div className="smaller__table">
							{newEmployees?.map((user) => (
								<MobileTable
									key={user?.employeeId}
									currentEmployeeAction={currentEmployeeAction}
									email={user?.email}
									employeeId={user?.employeeId}
									firstname={user?.firstname}
									handleEmployeeAction={handleEmployeeAction}
									lastname={user?.lastname}
									planName={user?.planName}
									setCurrentEmployee={setCurrentEmployee}
									setDeactivateEmployeeModal={setDeactivateEmployeeModal}
									setDeleteEmployeeModal={setDeleteEmployeeModal}
									user={user}
									threeDots={true}
									status={user?.status}
								/>
							))}
						</div>

						<Pagination
							employees={paginatedEmployee}
							page={page}
							setPage={setPage}
						/>
					</div>

					<EmployeeActionModal
						btnText={'Deactivate plan'}
						confirmText={'Please type Seun Elegboja to confirm deactivation.'}
						inputName={'Employee’s name'}
						inputValue={employeeDetails}
						mainText={
							'Deactivating the plan associated with Seun Elegboja will pause this employee’s plan subscription.'
						}
						setInputValue={setEmployeeDetails}
						setShow={() => setDeactivateEmployeeModal(false)}
						show={deactivateEmployeeModal}
						title={'Deactivate Plan'}
					/>
					<DeleteEmployeeModal
						setDeleteEmployeeModal={setDeleteEmployeeModal}
						show={deleteEmployeeModal}
						title={'Delete Employee'}
						currentEmployee={currentEmployee}
						setRemoveEmployee={setRemoveEmployee}
					/>
				</div>
			) : (
				<ActiveBenefits
					icon={skeletonLoading}
					text={`You have added no employees yet. Once you start adding them, they will show up here.`}
					handleClick={() => handleAddEmployees()}
					btnText={`Add Employee`}
				/>
			)}
			<AddEmployeeModal
				addEmployeeModal={addEmployeeModal}
				setAddEmployeeModal={setAddEmployeeModal}
				setAddEmployeeSuccess={setAddEmployeeSuccess}
				setExisitingUserModal={setExisitingUserModal}
				setAddEmployeeDetails={setAddEmployeeDetails}
			/>
			<ConfirmExisitingUser
				exisitingUserModal={exisitingUserModal}
				setExisitingUserModal={setExisitingUserModal}
				addEmployeeDetails={addEmployeeDetails}
				setAddEmployeeSuccess={setAddEmployeeSuccess}
			/>
			<ModalResponse
				setAddEmployeeSuccess={setAddEmployeeSuccess}
				addEmployeeSuccess={addEmployeeSuccess}
			/>
			<DeleteEmployeeSuccess
				setRemoveEmployee={setRemoveEmployee}
				removeEmployee={removeEmployee}
				currentEmployee={currentEmployee}
			/>
		</>
	);
};

export default Employees;
