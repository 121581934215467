import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from '../../../components/forms/Input';
import Button from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserDetails } from '../../../features/user/userSlice';

const Accounts = () => {
	const { businessDetails, updateLoading } = useSelector((store) => store.user);
	const dispatch = useDispatch();
	const schema = Yup.object({
		name: Yup.string(),
		email: Yup.string().email(),
	});
	const { handleSubmit, handleChange, handleBlur, values } = useFormik({
		initialValues: {
			email: businessDetails?.businessEmail,
			name: businessDetails?.businessName,
		},
		validationSchema: schema,
		onSubmit: (values) => {
			dispatch(
				updateUserDetails({
					businessName: '',
					businessEmail: values?.email,
					firstName: '',
					lastName: '',
					contactEmail: '',
					phoneNumber: '',
					callBackUrl: `${window.location.origin}/auth/verifying-mail`,
				})
			);
		},
	});

	return (
		<div className="settings__container">
			<div className="settings__container--header">
				<h2>Business Logo</h2>
				<div className="img__container">
					{businessDetails?.businessName.substring(0, 2)}
				</div>
			</div>
			<div className="settings__container--body">
				<h2>Business Info</h2>
				<p>Basic information about your business.</p>
				<form className="settings_form" onSubmit={handleSubmit}>
					<Input
						type="name"
						id="name"
						value={values.name}
						onChange={handleChange}
						onBlur={handleBlur}
						label="Business Name"
						name="name"
						disabled={true}
					/>
					<Input
						type="email"
						id="email"
						value={values.email}
						onChange={handleChange}
						onBlur={handleBlur}
						label="Business email"
						name="email"
					/>

					<p>
						If this email is edited, verification will be needed for the change
						to be effective.
					</p>

					<Button
						variant="primary"
						type="submit"
						className="mt-32"
						block
						disabled={updateLoading}
					>
						{updateLoading ? 'Loading...' : 'Update'}
					</Button>
				</form>
			</div>
		</div>
	);
};

export default Accounts;
