import React, { useState, useEffect } from 'react';
import SideModal from './modals/SideModal';
import { useNavigate } from 'react-router-dom';
import axaLogo from '../assets/svgs/axa.svg';
import cowrywiseLogo from '../assets/svgs/cowrywise.svg';
import hygeiaLogo from '../assets/svgs/hygeia.svg';
import octamile from '../assets/images/octamile.jpeg';
import peswa from '../assets/images/peswa.jpeg';
import flanceLogo from '../assets/svgs/flance.svg';
import { fmtCurrency } from '../utils/helpers';
import Icon from './Icon';

const Cart = ({
	selectedPlans,
	setSelectedPlans,
	setPlansSelcted,
	plansSelected,
	openCart,
	setOpenCart,
}) => {
	const navigate = useNavigate();
	const [btnDisbled, setBtnDisabled] = useState(false);
	const handleRemoveBenefits = (plan, planName) => {
		const updatedItems = selectedPlans.filter((item) => item.id !== plan);
		setSelectedPlans(updatedItems);
		const updateSelectedPlans = plansSelected.filter(
			(item) => item !== planName
		);
		setPlansSelcted(updateSelectedPlans);
	};

	const selectImage = (img) => {
		if (img == 'AXA Mansard' || img == 'Axa Mansard') {
			return axaLogo;
		} else if (img == 'CowryWise' || img == 'Cowrywise') {
			return cowrywiseLogo;
		} else if (img == 'Octamile') {
			return octamile;
		} else if (img == 'Peswa') {
			return peswa;
		} else if (img == 'Hygeia') {
			return hygeiaLogo;
		} else {
			return flanceLogo;
		}
	};

	useEffect(() => {
		if (selectedPlans.length < 1) {
			setBtnDisabled(true);
		} else {
			setBtnDisabled(false);
		}
	}, [selectedPlans]);

	const handleClick = () => {
		// localStorage.setItem('selectedBenefits', JSON.stringify(selectedPlans));
		navigate('/plan/plans-creation');
	};

	return (
		<SideModal
			headerText={`${selectedPlans.length} items in cart`}
			openSideModal={openCart}
			setOpenSideModal={setOpenCart}
			handleClick={handleClick}
			btnText={'continue plan creation'}
			isCart={true}
			btnDisbled={btnDisbled}
		>
			{selectedPlans.map((plan) => (
				<div className="cart-item" key={plan?.id}>
					<div className="company__details">
						<img
							src={selectImage(plan?.benefitProvider)}
							alt={plan?.benefitProvider}
						/>
						<div style={{ width: '100%' }}>
							<div className="company__details--plan">
								<div className="__name">
									<h2>{plan?.benefitName}</h2>
									<p>{plan?.benefitProvider}</p>
								</div>
								<p className="price">
									{fmtCurrency(
										plan?.defaultPrice ? plan?.defaultPrice : plan?.benefitPrice
									)}
									<span>
										/{plan?.defaultDuration ? plan?.defaultDuration : 'Monthly'}
									</span>
								</p>
								{/* <p>Remove</p> */}
							</div>
							<button
								onClick={() =>
									handleRemoveBenefits(plan?.id, plan?.benefitName)
								}
								className="remove"
							>
								Remove
							</button>
						</div>
					</div>
					{plan?.isCorporate && (
						<div className="tip__container">
							<Icon id="tip" width="30" height="16" />
							<p>
								Note: This benefit allows a maximum of{' '}
								<b>{plan?.maximumEnrollee} employees </b> and a minimum of{' '}
								<b>{plan?.minimumEnrollee} employees</b>.
							</p>
						</div>
					)}
				</div>
			))}
		</SideModal>
	);
};

export default Cart;
