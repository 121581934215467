import React from 'react';
import { Modal } from '../Modal';
import { fmtCurrency } from '../../utils/helpers';
import Button from '../Button';
// import Button from "components/Buttons";
// import Modal from "components/Modal";
// import { fmtCurrency } from "utils/functions";
// import { Link } from "react-router-dom";
// import Icon from "components/Icon";
// import { getUserPaymentDetails } from "services/payment";

const ConfirmPaymentDetails = ({
	confirmDetailsModal,
	setConfirmDetailsModal,
	userName,
	PlanName,
	makePayment,
	amount,
	loading,
	benefits,
	name,
}) => {
	return (
		<Modal
			handleClose={() => {
				setConfirmDetailsModal(false);
			}}
			show={confirmDetailsModal}
			title={'Confirm Details'}
			containerClassName="confirm-deets-modal"
		>
			<div>
				<p className="confirm-deets-modal__title">{"You're about to pay"}</p>
				<div className="confirm-deets-modal__price-banner mx-auto mt-24">
					<h3 className="__top--value">
						<span className="">{fmtCurrency(amount)}</span>
					</h3>
				</div>

				<ul className="confirm-deets-modal__list">
					<li className="confirm-deets-modal__list--deets">
						<p>{name}</p>
						<p>{userName}</p>
					</li>
					<li className="confirm-deets-modal__list--deets">
						<p>Plan Name</p>
						<p>{PlanName}</p>
					</li>
					<li className="confirm-deets-modal__list--deets">
						<p>{benefits ? 'Benefits' : ''}</p>
						<p>
							{benefits?.map((benefit) => (
								<span className="ml-16" key={benefit?.id}>
									{benefit?.benefitName},{' '}
								</span>
							))}
						</p>
					</li>
				</ul>

				<Button
					variant="primary"
					block
					className="mt-40"
					type="button"
					onClick={makePayment}
					disabled={loading}
				>
					{loading ? 'Loading...' : 'Confirm and Pay'}
				</Button>
			</div>
		</Modal>
	);
};

export default ConfirmPaymentDetails;
