import axios from 'axios';
import { getUserFromLocalStorage } from './localstorage';
import { logoutUser } from '../features/user/userSlice';

const customFetch = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

// console.log(process.env)
customFetch.interceptors.request.use((config) => {
	const user = getUserFromLocalStorage();
	if (user) {
		config.headers['Authorization'] = `Bearer ${user.token}`;
	}
	return config;
});

export const checkForUnauthorizedResponse = (error, thunkAPI) => {
	if (error.response.status === 401) {
		thunkAPI.dispatch(logoutUser());
	
		window.location.href = '/auth/login';
	}
	return thunkAPI.rejectWithValue(error.response.data.message);
};

export default customFetch;
