import React from 'react';

const TestimonialCarousel = ({
	testimonial,
	companyRep,
	repTitle,
	companyLogo,
	altText,
}) => {
	return (
		<div className="carousel__container">
			<div className="carousel__container--header">
				<p>{testimonial}</p>
			</div>
			<div className="carousel__container--footer">
				<div className="name">
					<h4>{companyRep}</h4>
					<p>{repTitle}</p>
				</div>
				<div className="banner">
					<img src={companyLogo} alt={altText} />
				</div>
			</div>
		</div>
	);
};

export default TestimonialCarousel;
