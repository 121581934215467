import React, { useEffect, useState } from 'react';
import Icon from '../../../components/Icon';
import { dateFormat, fmtCurrency, paginate } from '../../../utils/helpers';
import WalletTable from '../../../components/table/WalletTable';
import skeletonLoading from '../../../assets/svgs/Notransactions.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
	getWalletDetails,
	getWalletTransactionDetails,
} from '../../../features/wallet/walletSlice';
import PageLoader from '../../../components/PageLoader';
import Pagination from '../../../components/Pagination';
import MobileTable from '../../../components/modals/MobileTable';
// import ActiveBenefits from '../../../components/ActiveBenefits';/

const Wallet = () => {
	const [isVisible, setIsVisible] = useState(true);
	const [transationHistory, setTransactionHistory] = useState([]);
	const [transactionPage, setTransactionPage] = useState(0);
	const [paginatedTransactions, setPaginatedTransactions] = useState([]);
	const columns = ['Date', 'description', 'Amount', 'Reference', 'Status'];
	const dispatch = useDispatch();
	const { isLoading, walletDetails, transactionDetails } = useSelector(
		(store) => store.wallet
	);
	useEffect(() => {
		dispatch(getWalletDetails());
		dispatch(getWalletTransactionDetails());
	}, []);

	useEffect(() => {
		if (transactionDetails) {
			setPaginatedTransactions(paginate(transactionDetails, 7));
		}
	}, [transactionDetails]);

	useEffect(() => {
		if (paginatedTransactions) {
			setTransactionHistory(paginatedTransactions[transactionPage]);
		}
	}, [paginatedTransactions, transactionPage]);

	return (
		<>
			{isLoading ? (
				<PageLoader />
			) : (
				<div className="wallet">
					<div className="wallet__card">
						<div className="wallet__card--container">
							<div className="header">
								<h4>Credit balance</h4>

								<Icon
									id={isVisible ? 'eye-white' : 'eye-white-closed'}
									width="20"
									height={isVisible ? '15' : '13'}
									className={'svg'}
									onClick={() => setIsVisible((visible) => !visible)}
								/>
							</div>
							<div className="amount">
								<p>
									{isVisible ? fmtCurrency(walletDetails?.balance) : '******'}
								</p>
							</div>
						</div>
					</div>

					<div className="wallet__table">
						<h2>Transaction History</h2>
						<p>All activities performed within your credit</p>
						{transactionDetails.length > 0 ? (
							<div className="table-container">
								<WalletTable data={transationHistory} columns={columns} />
								<div className="smaller__table">
									{transationHistory?.map((plan, index) => (
										<MobileTable
											key={index}
											email={`${dateFormat(
												plan?.dateCreated,
												'MMM do, yyyy HH:mm' || '--'
											)}`}
											firstname={plan?.narration}
											renewalDate={fmtCurrency(plan?.amount)}
											threeDots={false}
											nameCircle={false}
											planName={' '}
										/>
									))}
								</div>

								<Pagination
									employees={paginatedTransactions}
									page={transactionPage}
									setPage={setTransactionPage}
								/>
							</div>
						) : (
							// <WalletTable columns={columns} />
							<div className="noTransactions__container">
								<img src={skeletonLoading} alt="loading" />
								<p>
									No transactions yet. Your transaction will show up here start
									transacting.
								</p>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Wallet;
