import React, { useState } from 'react';
import Icon from './Icon';
import Button from './Button';

const SearchAddContainer = ({
	searchInput,
	changeInput,
	handleCLick,
	btnTitle,
	filterOptions,
	selectedFilter,
	setSelectedFilter,
}) => {
	const [openFilterList, setOpenFilterList] = useState(false);
	
	return (
		<div className="employee__header">
			<div className="employee__header--container">
				<div className="employee__header--search">
					<Icon width="16" height="16" id="search" />
					<input
						type="text"
						id="savingsAmount"
						value={searchInput}
						onChange={changeInput}
						placeholder="Search name"
					/>
				</div>
				{filterOptions && (
					<div
						className="employee__header--filterContainer"
						onMouseLeave={() => setOpenFilterList(false)}
					>
						<div
							className="employee__header--filter"
							onMouseOver={() => setOpenFilterList(true)}
						>
							<p>{selectedFilter ? selectedFilter : 'Filter by'}</p>
							<Icon id="down-caret" width="10" height="4" />
						</div>
						{openFilterList && (
							<div className="filterList">
								<ul>
									{filterOptions.map((item, index) => (
										<li
											onClick={() => {
												setSelectedFilter(item);
											}}
											key={index}
										>
											{item}
										</li>
									))}

									{/* <li>Pending</li>
									<li>Expired</li>
									<li>Failed</li> */}
								</ul>
							</div>
						)}
					</div>
				)}
			</div>
			<div className="employee__header--btn">
				{btnTitle && (
					<Button
						variant="primary"
						size="sm"
						className="btn--block"
						onClick={handleCLick}
						// disabled={loader}
					>
						{btnTitle}
					</Button>
				)}
			</div>
		</div>
	);
};

export default SearchAddContainer;
