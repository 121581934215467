import React from 'react';
import { Modal } from '../Modal';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { addExisitingEmployees } from '../../features/employees/employeesSlice';

const ConfirmExisitingUser = ({
	exisitingUserModal,
	setExisitingUserModal,
	addEmployeeDetails,
	setAddEmployeeSuccess,
}) => {
	const dispatch = useDispatch();
	const { addEmployeeLoading } = useSelector((store) => store.employees);
	const handleClick = () => {
		dispatch(
			addExisitingEmployees({
				employeeMails: [`${addEmployeeDetails?.email}`],
				callBackUrl: `${window.location.origin}/auth/verifyExisitingUser`,
			})
		).then((resp) => {
			if (resp?.error) {
				setExisitingUserModal(false);
			} else {
				setExisitingUserModal(false);
				setAddEmployeeSuccess(true);
			}
		});
	};
	// useEffect(() => {
	// 	if (employeeCreateSuccess) {
	// 	}
	// }, [employeeCreateSuccess]);
	return (
		<Modal
			title="Confirm Employee"
			show={exisitingUserModal}
			handleClose={() => setExisitingUserModal(false)}
		>
			<div className="modal__content">
				<p
					style={{
						maxWidth: '100%',
						margin: 'auto',
						lineHeight: '1.5',
					}}
					className="co-gray"
				>
					<b>{`${addEmployeeDetails?.firstName} ${addEmployeeDetails?.lastName}`}</b>{' '}
					already has a flance account click {`"add employee"`} to proceed
				</p>
				<Button
					variant="primary"
					className="btn--block mt-16"
					disabled={addEmployeeLoading}
					onClick={() => handleClick()}
				>
					{addEmployeeLoading ? 'Adding...' : 'Add Employee'}
				</Button>
			</div>
		</Modal>
	);
};

export default ConfirmExisitingUser;
