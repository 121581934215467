import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';
import { toast } from 'react-hot-toast';
// import { paginate } from '../../utils/helpers';
import { removeUserFromLocalStorage } from '../../utils/localstorage';
import { logoutUser } from '../user/userSlice';

const initialState = {
	isLoading: false,
	addEmployeeLoading: false,
	employees: [],
	employeeCreateSuccess: false,
	employeeCreateFail: false,
	employeeSignUp: null,
	emplyeeDeleteLoading: false,
	deleteEmployeeSuccess: false,
	employeeDetails: {},
	failedEmployeeDetails: false,
};

const notify = (message) => toast.error(message);
const notifySuccess = (message) => toast.success(message);

export const getAllEmployees = createAsyncThunk(
	'employees/allEmployees',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.get('business/employees');
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

export const addEmployees = createAsyncThunk(
	'employees/addEmployees',
	async (employeeDetails, thunkAPI) => {
		console.log(employeeDetails);
		try {
			const resp = await customFetch.post('business/employee', employeeDetails);
			// console.log(resp);
			return resp?.data;
		} catch (error) {
			if (error.response.status === 401) {
				// thunkAPI.dispatch(clearStore());
				// return thunkAPI.rejectWithValue(
				// 	'Full authentication is required to access this resource'
				// );
				thunkAPI.dispatch(logoutUser());
				window.location.href = '/auth/login';
			}
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const addExisitingEmployees = createAsyncThunk(
	'employees/addExisitingEmployees',
	async (employeeDetails, thunkAPI) => {
		try {
			const resp = await customFetch.put(
				'business/employee/user',
				employeeDetails
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

export const deleteEmployee = createAsyncThunk(
	'employees/deleteEmployees',
	async (employee, thunkAPI) => {
		try {
			const resp = await customFetch.delete(`business?employeeId=${employee}`);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const getEmployeeDetails = createAsyncThunk(
	'employees/getEmployeeDetails',
	async (employee, thunkAPI) => {
		try {
			const resp = await customFetch.get(`business/employees/${employee}`);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

export const signupEmployee = createAsyncThunk(
	'employees/signupEmployees',
	async (employee, thunkAPI) => {
		try {
			const resp = await customFetch.put(`/employee`, employee);
			return resp?.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data?.message);
		}
	}
);
export const resendInvitationLink = createAsyncThunk(
	'employees/resendInvitationLink',
	async (employee, thunkAPI) => {
		try {
			const resp = await customFetch.post(
				`business/user/resend-verification`,
				employee
			);
			return resp?.data;
		} catch (error) {
			console.log(error);
			return thunkAPI.rejectWithValue(error?.response?.data?.message);
		}
	}
);

const employeesSlice = createSlice({
	name: 'employees',
	initialState,
	reducers: {
		resetEmployeeSuccess: (state) => {
			state.employeeCreateSuccess = false;
		},
		resetEmployeeFail: (state) => {
			state.employeeCreateFail = false;
		},
		deleteEmployeeReset: (state) => {
			state.deleteEmployeeSuccess = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllEmployees.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getAllEmployees.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.employees = payload?.data;
			})

			.addCase(getAllEmployees.rejected, (state, { payload }) => {
				state.isLoading = false;
				console.log(payload);
			})
			.addCase(addEmployees.pending, (state) => {
				state.addEmployeeLoading = true;
			})
			.addCase(addEmployees.fulfilled, (state) => {
				state.addEmployeeLoading = false;
				state.employeeCreateSuccess = true;
			})
			.addCase(addEmployees.rejected, (state, { payload }) => {
				state.addEmployeeLoading = false;
				state.employeeCreateFail = true;
				notify(payload?.message);
			})
			.addCase(getEmployeeDetails.pending, (state) => {
				state.addEmployeeLoading = true;
				state.failedEmployeeDetails = false;
			})
			.addCase(getEmployeeDetails.fulfilled, (state, { payload }) => {
				state.addEmployeeLoading = false;
				state.employeeDetails = payload;
				state.failedEmployeeDetails = false;
			})
			.addCase(getEmployeeDetails.rejected, (state, { payload }) => {
				state.addEmployeeLoading = false;
				state.failedEmployeeDetails = true;
				notify(payload?.message);
			})
			.addCase(deleteEmployee.pending, (state) => {
				state.addEmployeeLoading = true;
			})
			.addCase(deleteEmployee.fulfilled, (state) => {
				state.addEmployeeLoading = false;
				state.deleteEmployeeSuccess = true;
			})
			.addCase(deleteEmployee.rejected, (state, { payload }) => {
				state.addEmployeeLoading = false;
				state.deleteEmployeeSuccess = false;
				notify(payload);
			})
			.addCase(resendInvitationLink.pending, (state) => {
				state.addEmployeeLoading = true;
			})
			.addCase(resendInvitationLink.fulfilled, (state, { payload }) => {
				state.addEmployeeLoading = false;
				notifySuccess(payload?.message);
			})
			.addCase(resendInvitationLink.rejected, (state, { payload }) => {
				state.addEmployeeLoading = false;
				notify(payload);
			})
			.addCase(addExisitingEmployees.pending, (state) => {
				state.addEmployeeLoading = true;
			})
			.addCase(addExisitingEmployees.fulfilled, (state) => {
				state.addEmployeeLoading = false;
				state.employeeCreateSuccess = true;
				// notifySuccess(payload);
			})
			.addCase(addExisitingEmployees.rejected, (state, { payload }) => {
				state.addEmployeeLoading = false;
				notify(payload);
			})
			.addCase(signupEmployee.pending, (state) => {
				state.addEmployeeLoading = true;
			})
			.addCase(signupEmployee.fulfilled, (state, { payload }) => {
				state.addEmployeeLoading = false;
				state.employeeSignUp = payload;
				notifySuccess(payload?.message);
				removeUserFromLocalStorage();

				window.location.href = `${process.env.REACT_APP_FLANCE_APP_URL}login`;
			})
			.addCase(signupEmployee.rejected, (state, { payload }) => {
				state.addEmployeeLoading = false;
				notify(payload);
			});
	},
});

export const { resetEmployeeSuccess, resetEmployeeFail, deleteEmployeeReset } =
	employeesSlice.actions;
export default employeesSlice.reducer;
