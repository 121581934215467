export const navigations = [
	{
		id: 0,
		link: '/plan',
		icon: 'plans',
		coloredIcon: 'blue-plans',
		name: 'Plans',
		navName: 'plan',
	},
	{
		id: 1,
		link: '/employees',
		icon: 'person',
		coloredIcon: 'blue-person',
		name: 'Employees',
		navName: 'employees',
	},
	{
		id: 2,
		link: '/credit',
		icon: 'credit',
		coloredIcon: 'blue-credit',
		name: 'Credit',
		navName: 'credit',
	},
	{
		id: 3,
		link: '/settings',
		icon: 'settings',
		coloredIcon: 'blue-settings',
		name: 'Settings',
		navName: 'settings',
	},
];

export const settingsNavigation = [
	{
		id: 0,
		link: '/settings',
		name: 'Account',
		pathName: undefined,
	},
	{
		id: 1,
		link: '/settings/contact-info',
		name: 'Contact Info',
		pathName: 'contact-info',
	},
	{
		id: 2,
		link: '/settings/card',
		name: 'Card',
		pathName: 'card',
	},
	{
		id: 3,
		link: '/settings/documents',
		name: 'Documents',
		pathName: 'documents',
	},
	{
		id: 4,
		link: '/settings/security',
		name: 'Security',
		pathName: 'security',
	},
];
