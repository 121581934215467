import React from 'react';
import { settingsNavigation } from '../../../utils/navigations';
import { Link, Outlet, useLocation } from 'react-router-dom';

const Settings = () => {
	const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split('/');

	return (
		<div className="settings">
			<div className="settings__header">
				{settingsNavigation.map((navigation) => (
					<Link
						to={navigation.link}
						className={`--link ${
							splitLocation[2] === navigation.pathName ? 'selected' : ''
						}`}
						key={navigation.id}
					>
						<p>{navigation?.name}</p>
					</Link>
				))}
			</div>
			<div className="settings__body">
				<Outlet />
			</div>
		</div>
	);
};

export default Settings;
