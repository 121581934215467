import React, { useEffect, useState } from 'react';
import SearchAddContainer from '../../../components/SearchAddContainer';
import PlanListTable from '../../../components/table/PlanListTable';
import Pagination from '../../../components/Pagination';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ActiveBenefits from '../../../components/ActiveBenefits';
import {
	addPlanDetails,
	planPaymentTransactions,
	setPlanActiveTrue,
} from '../../../features/plan/planSlice';
import skeletonLoading from '../../../assets/svgs/loading.svg';
import {
	dateFormat,
	fmtCurrency,
	getQueryVariable,
	paginate,
} from '../../../utils/helpers';
import MobileTable from '../../../components/modals/MobileTable';
import RenewalPaymentModal from '../../../components/modals/RenewalPaymentModal';
import PlanStatusCard from '../../../components/PlanStatusCard';
// import PlanStatusCard from '../../../components/PlanStatusCard';

const PlanList = () => {
	const navigate = useNavigate();
	const { allTransactions } = useSelector((store) => store.plan);
	const [planSearch, setPlanSearch] = useState('');
	const [paginatedPlanList, setPaginatedPlanList] = useState([]);
	const [paymentSuccess, setPaymentSuccess] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState('');
	const dispatch = useDispatch();
	const [planFilteredByStatus, setPlanFilteredByStatus] = useState([]);
	// const [planListFilter, setPlanListFilter] = useState([]);
	const [followers, setFollowers] = useState([]);
	const [page, setPage] = useState(0);

	useEffect(() => {
		dispatch(planPaymentTransactions());
	}, []);

	const planListFilter = planFilteredByStatus?.filter((plans) => {
		if (plans?.planName?.toLowerCase().includes(planSearch.toLowerCase())) {
			return plans;
		}
	});

	const planListActiveFilter = (status) => {
		const numOfPlanStatus = allTransactions?.data?.filter((plans) => {
			if (plans?.status?.toLowerCase() === status) {
				return plans;
			}
		});
		return numOfPlanStatus?.length;
	};

	useEffect(() => {
		if (selectedFilter) {
			if (selectedFilter === 'All') {
				setPlanFilteredByStatus(allTransactions?.data);
				return;
			}
			const numOfPlanStatus = allTransactions?.data?.filter((plans) => {
				if (plans?.status?.toLowerCase() === selectedFilter) {
					return plans;
				}
			});
			setPlanFilteredByStatus(numOfPlanStatus);
		} else {
			setPlanFilteredByStatus(allTransactions?.data);
		}
		setPlanFilteredByStatus(allTransactions?.data);
	}, [allTransactions?.data, selectedFilter]);

	useEffect(() => {
		if (paginatedPlanList) {
			setFollowers(paginatedPlanList[page]);
		}
	}, [paginatedPlanList, page]);

	useEffect(() => {
		if (planListFilter) {
			setPaginatedPlanList(paginate(planListFilter));
		}
	}, [planListFilter]);

	useEffect(() => {
		const payload = {
			// employeeCallBackUrl: `${process.env.REACT_APP_FLANCE_APP_URL}login`,
			// businessCallBackUrl: `${window.location.origin}/auth/login`,
			status: getQueryVariable('status'),
			transactionReference: getQueryVariable('tx_ref'),
			transactionId: getQueryVariable('transaction_id'),
		};
		if (payload.status === 'successful' || payload.status === 'completed') {
			setPaymentSuccess(true);
		}
	}, [getQueryVariable('status')]);

	return allTransactions?.data?.length < 1 ? (
		<ActiveBenefits
			icon={skeletonLoading}
			text={`You have no plan yet. Start creating to help your employees stay
			protected 24/7.`}
			handleClick={() => {
				dispatch(setPlanActiveTrue());
				navigate('/plan/all-plans');
			}}
			btnText={`Create a plan`}
		/>
	) : (
		<div className="planlist">
			<div className="plan__status">
				<PlanStatusCard
					isRemider={false}
					numberOfPlan={planListActiveFilter('active')}
					status={'active'}
					statusName={'Active Plans'}
					total={allTransactions?.data?.length}
				/>
				<PlanStatusCard
					// isRemider={true}
					numberOfPlan={planListActiveFilter('pending')}
					status={'pending'}
					statusName={'Pending Plans'}
					total={allTransactions?.data?.length}
				/>
				<PlanStatusCard
					isRemider={false}
					numberOfPlan={planListActiveFilter('expired')}
					status={'expired'}
					statusName={'Expired Plans'}
					total={allTransactions?.data?.length}
				/>
			</div>
			<SearchAddContainer
				searchInput={planSearch}
				changeInput={(e) => setPlanSearch(e.target.value)}
				btnTitle={'Create a new plan'}
				handleCLick={() => {
					navigate('/plan/all-plans');
				}}
				filterOptions={['All', 'active', 'pending', 'expired', 'failed']}
				selectedFilter={selectedFilter}
				setSelectedFilter={setSelectedFilter}
			/>
			<div className="table-container">
				<PlanListTable data={followers} />
				<div className="smaller__table">
					{followers?.map((plan) => (
						<MobileTable
							key={plan?.planId}
							email={fmtCurrency(plan?.amount)}
							employeeId={plan?.employeeId}
							firstname={plan?.planName}
							planName={plan?.duration}
							// renewalDate={plan?.endDate}
							renewalDate={dateFormat(plan?.endDate, 'do MMM, yyyy' || '--')}
							threeDots={false}
							nameCircle={false}
							handleClick={() => {
								dispatch(addPlanDetails(plan));
								navigate(`/plan/plans-details/${plan?.planId}`);
							}}
						/>
					))}
				</div>
				<Pagination
					employees={paginatedPlanList}
					page={page}
					setPage={setPage}
				/>
			</div>
			<RenewalPaymentModal
				title={'Renewal successful!'}
				bodyText="You’ve successfully renewed your plan."
				handleClick={() => {
					setPaymentSuccess(false);
					navigate('/plan');
				}}
				paymentSuccess={paymentSuccess}
			/>
		</div>
	);
};

export default PlanList;
