import React, { useEffect } from 'react';
import Button from '../../components/Button';
import Input from '../../components/forms/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser } from '../../features/user/userSlice';

const Login = () => {
	const navigate = useNavigate();
	const { userDetails, isLoading } = useSelector((store) => store.user);
	const dispatch = useDispatch();
	const schema = Yup.object({
		email: Yup.string()
			.required('Email field is required')
			.email('Invalid email address'),
		password: Yup.string().required('Required'),
	});
	const { handleSubmit, handleChange, handleBlur, touched, values, errors } =
		useFormik({
			initialValues: {
				email: '',
				password: '',
			},
			validationSchema: schema,
			onSubmit: (values) => {
				dispatch(
					loginUser({
						username: values.email,
						password: values.password,
					})
				);
			},
		});

	useEffect(() => {
		if (userDetails) {
			navigate('/plan');
		}
	}, []);
	return (
		<div className="auth__form--content">
			<div className="auth__form--header">
				<div className="header__container">
					<div className="header__container--content">
						<h3>Welcome Back!</h3>
						<p>Login to access your account.</p>
					</div>
				</div>
			</div>
			<form className="__form" onSubmit={handleSubmit}>
				<Input
					type="email"
					id="email"
					value={values.email}
					onChange={handleChange}
					onBlur={handleBlur}
					label="Business email"
					name="email"
					error={
						touched.email && errors.email ? (
							<small className="error">{errors.email}</small>
						) : null
					}
				/>

				<Input
					type="password"
					id="password"
					value={values.password}
					onChange={handleChange}
					onBlur={handleBlur}
					label="Password"
					name="password"
					error={
						touched.password && errors.password ? (
							<small className="error">{errors.password}</small>
						) : null
					}
				/>
				<div style={{ marginTop: '24px' }}>
					<Link
						className="btn btn--link btn--link-secondary"
						to="/auth/forgot-password"
					>
						Forgot password?
					</Link>
				</div>
				<Button
					variant="primary"
					type="submit"
					className="mt-40"
					block
					disabled={isLoading}
				>
					{isLoading ? 'Loading...' : 'Log in'}
				</Button>
			</form>
			<p className="mt-32 tx-c extralinnk">
				{"You don't have a business account?"}{' '}
				<Link className="btn btn--link btn--link-secondary" to="/auth/signup">
					Create an account
				</Link>
			</p>
		</div>
	);
};

export default Login;
