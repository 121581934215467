import React from 'react';
import { Modal } from '../Modal';
import Button from '../Button';
import Icon from '../Icon';

const RenewalPaymentModal = ({
	title,
	bodyText,
	handleClick,
	paymentSuccess,
}) => {
	return (
		<Modal show={paymentSuccess} handleClose={handleClick} isHeader={false}>
			<div className="modal__content">
				{' '}
				<div className="employee-success tx-c">
					<span>
						<Icon id="check" width="64" height="64" />
					</span>

					<h2>{title}</h2>

					<p
						style={{
							maxWidth: '100%',
							margin: 'auto',
							lineHeight: '1.5',
							marginTop: '1.5rem',
						}}
						className="co-gray"
					>
						{bodyText}
					</p>
					<p className="mt-40 tx-c " style={{ width: '100%' }}>
						<Button
							variant="primary"
							className="btn--block"
							onClick={handleClick}
							// disabled={loader}
						>
							{/* {loader ? 'Sending...' : 'Resend email'} */}
							DONE
						</Button>
					</p>
				</div>
			</div>
		</Modal>
	);
};

export default RenewalPaymentModal;
