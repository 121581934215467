import React, { useEffect, useState } from 'react';
import Input from '../../components/forms/Input';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { useSelector, useDispatch } from 'react-redux';
import { registerUser } from '../../features/user/userSlice';

const Signup = () => {
	const [upperCase, setUpperCase] = useState(false);
	const [lowerCase, setLowerCase] = useState(false);
	const [special, setSpecial] = useState(false);
	const [length, setLength] = useState(false);
	const [isPasswordField, setIsPasswordField] = useState(false);
	const navigate = useNavigate();
	const [isBusinessSignup, setIsBusinessSignup] = useState(false);
	const phoneRegExp =
		/((^090)([0-9]))|((^070)([0-9]))|((^080)([0-9]))|((^091)([0-9]))|((^071)([0-9]))|((^081)([0-9]))(\d{7})/;

	const { user, isLoading } = useSelector((store) => store.user);

	const dispatch = useDispatch();

	const schema = isBusinessSignup
		? Yup.object({
				firstname: Yup.string().required('First name is required'),
				lastname: Yup.string().required('Last name is required'),
				phone: Yup.string()
					.required('Phone number is required')
					.matches(phoneRegExp, 'Phone number is not valid')
					.min(11, 'Phone number cannot be less than 11 characters')
					.max(14, 'Phone number cannot be longer than 14 characters'),
				email: Yup.string()
					.required('Email field is required')
					.email('Invalid email address'),
		  })
		: Yup.object({
				businessOfficialEmail: Yup.string()
					.required('Email field is required')
					.email('Invalid email address'),
				password: Yup.string()
					.required('Required')
					.min(8, 'Password must be at least 8 characters')
					.matches(
						/^(?=.*[a-z])/,
						'Password must contain at least one lowercase letter'
					)
					.matches(
						/^(?=.*[A-Z])/,
						'Password must contain at least one uppercase letter'
					)
					.matches(/^(?=.*[!@#$%^&*_-])/, 'Must Contain One Special Character'),
				businessName: Yup.string().required('business name is required'),
		  });

	const { handleSubmit, handleChange, handleBlur, touched, values, errors } =
		useFormik({
			initialValues: {
				businessOfficialEmail: '',
				password: '',
				businessName: '',
				firstname: '',
				lastname: '',
				phone: '',
				email: '',
			},
			validationSchema: schema,
			onSubmit: (values) => {
				isBusinessSignup
					? dispatch(
							registerUser({
								businessName: values.businessName,
								businessEmail: values.businessOfficialEmail,
								password: values.password,
								firstName: values.firstname,
								lastName: values.lastname,
								emailAddress: values.email,
								phoneNumber: values.phone,
								callBackUrl: `${window.location.origin}/auth/verifying-mail`,
							})
					  )
					: setIsBusinessSignup(true);
			},
		});
	useEffect(() => {
		if (values.password) {
			setUpperCase(/[A-Z]/g.test(values.password));
			setLowerCase(/[a-z]/g.test(values.password));
			setSpecial(/[!@#$%^&*_-]/g.test(values.password));
			setLength(values.password.length >= 8);
		}
		if (values.password === '') {
			setUpperCase(false);
			setLowerCase(false);
			setSpecial(false);
			setLength(false);
		}
	}, [values.password]);

	useEffect(() => {
		if (user) {
			navigate('/auth/verify-mail');
		}
	}, [user]);
	return (
		<div className="auth__form--content">
			<div className="auth__form--header">
				<div className="header__container">
					<div className="header__container--content">
						<h3>
							{isBusinessSignup
								? 'Provide a point of contact.'
								: 'Let’s get you started!'}
						</h3>
						<p>
							{isBusinessSignup
								? 'It will help ease handover when the need arises.'
								: 'Unlock your employee benefits.'}
						</p>
					</div>
					<span>{`${isBusinessSignup ? '2' : '1'}/2`}</span>
				</div>
			</div>
			<form className="__form __form-signup" onSubmit={handleSubmit}>
				{isBusinessSignup ? (
					<>
						<div className="inputgroup">
							<Input
								type="text"
								id="firstname"
								label="First name"
								name="firstname"
								value={values.firstname}
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder="First name"
								error={
									touched.firstname && errors.firstname ? (
										<small className="error">{errors.firstname}</small>
									) : null
								}
							/>
							<Input
								type="text"
								id="lastname"
								label="Last name"
								name="lastname"
								value={values.lastname}
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder="Last name"
								error={
									touched.lastname && errors.lastname ? (
										<small className="error">{errors.lastname}</small>
									) : null
								}
							/>
						</div>
						<Input
							type="email"
							id="email"
							label="Email address"
							name="email"
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							error={
								touched.email && errors.email ? (
									<small className="error">{errors.email}</small>
								) : null
							}
						/>
						<Input
							type="tel"
							id="phone"
							label="Phone number"
							name="phone"
							value={values.phone}
							onChange={handleChange}
							onBlur={handleBlur}
							error={
								touched.phone && errors.phone ? (
									<small className="error">{errors.phone}</small>
								) : null
							}
						/>
					</>
				) : (
					<>
						{' '}
						<Input
							type="text"
							id="businessName"
							label="Business name"
							name="businessName"
							value={values.businessName}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder="Business name"
							error={
								touched.businessName && errors.businessName ? (
									<small className="error">{errors.businessName}</small>
								) : null
							}
						/>
						<Input
							type="email"
							id="businessOfficialEmail"
							label="Business Official Email"
							name="businessOfficialEmail"
							value={values.businessOfficialEmail}
							onChange={handleChange}
							onBlur={handleBlur}
							error={
								touched.businessOfficialEmail &&
								errors.businessOfficialEmail ? (
									<small className="error">
										{errors.businessOfficialEmail}
									</small>
								) : null
							}
						/>
						<Input
							type="password"
							id="password"
							placeholder="Create Password"
							label="Create Password"
							name="password"
							value={values.password}
							onChange={handleChange}
							onBlur={handleBlur}
							onFocus={() => setIsPasswordField(true)}
							error={
								touched.password && errors.password ? (
									<small className="error">{errors.password}</small>
								) : null
							}
						/>
						{isPasswordField && (
							<div className="password-rules">
								<div className="lineIndicator">
									<div className={`div ${length ? 'passed' : ''}`}></div>
									<div className={`div ${upperCase ? 'passed' : ''}`}></div>
									<div className={`div ${lowerCase ? 'passed' : ''}`}></div>
									<div className={`div ${special ? 'passed' : ''}`}></div>
								</div>
								<div className="actualRules">
									<div className={`rule ${length ? 'active' : ''}`}>
										<div className="circle"></div>
										<p>8 or more characters</p>
									</div>
									<div className={`rule ${upperCase ? 'active' : ''}`}>
										<div className="circle"></div>
										<p>An uppercase letter</p>
									</div>
									<div className={`rule  ${lowerCase ? 'active' : ''}`}>
										<div className="circle"></div>
										<p>A lowercase letter</p>
									</div>
									<div className={`rule  ${special ? 'active' : ''}`}>
										<div className="circle "></div>
										<p>A symbol (=!@#&$%)</p>
									</div>
								</div>
							</div>
						)}
					</>
				)}

				{isBusinessSignup && (
					<div className="__form-signup-tip">
						<span>
							<Icon id={'tip'} width={'20'} height={'16'} />
						</span>

						<p>
							Your point of contact can be your HR, Payroll Officer, Admin etc.
							Note that an email will be sent to notify them.
						</p>
					</div>
				)}

				<Button
					variant="primary"
					type="submit"
					className={isBusinessSignup ? 'mt-24' : 'mt-40'}
					block
					disabled={isLoading}
				>
					{isBusinessSignup
						? isLoading
							? 'Loading...'
							: 'Finish, let’s go!'
						: 'Continue'}
				</Button>
			</form>
			{isBusinessSignup ? (
				<></>
			) : (
				<>
					<p className="mt-32 tx-c extralinnk">
						Already have a business account?{' '}
						<Link
							className="btn btn--link btn--link-secondary"
							to="/auth/login"
						>
							Login
						</Link>
					</p>
					<p className="mt-32 tx-c acknwmnt">
						By clicking “Continue”, you acknowledge that you have read and
						understood, and agreed to our{' '}
						<a
							className="btn btn--link btn--link-secondary"
							href="https://www.flanceapp.com/terms"
							target={`_blank`}
						>
							Terms of Use
						</a>{' '}
						and{' '}
						<a
							className="btn btn--link btn--link-secondary"
							href="https://www.flanceapp.com/privacy"
							target={`blank`}
						>
							Privacy Policy
						</a>
						.
					</p>
				</>
			)}
		</div>
	);
};

export default Signup;
