import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';
import { toast } from 'react-hot-toast';
// import { paginate } from '../../utils/helpers';

const initialState = {
	isLoading: false,
	walletDetails: [],
	transactionDetails: [],
};

const notify = (message) => toast.error(message);
// const notifySuccess = (message) => toast.success(message);

export const getWalletDetails = createAsyncThunk(
	'wallet/walletDetails',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.get(`business/credit`);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const getWalletTransactionDetails = createAsyncThunk(
	'wallet/walletTransactionDetails',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.get(`business/credit/transactions`);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

const walletSlice = createSlice({
	name: 'wallet',
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(getWalletDetails.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getWalletDetails.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.walletDetails = payload;
			})
			.addCase(getWalletDetails.rejected, (state, { payload }) => {
				state.isLoading = false;
				notify(payload?.message);
			})
			.addCase(getWalletTransactionDetails.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getWalletTransactionDetails.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.transactionDetails = payload;
			})
			.addCase(getWalletTransactionDetails.rejected, (state, { payload }) => {
				state.isLoading = false;
				notify(payload?.message);
			});
	},
});

export default walletSlice.reducer;
