import React from 'react';
import { useNavigate } from 'react-router-dom';
import AuthStatus from '../../../components/AuthStatus';

const ForgotPasswordMail = () => {
	const Navigate = useNavigate();
	const handleClick = () => {
		Navigate('/auth/login');
	};
	return (
		<AuthStatus
			authDetails={
				'We have sent a secure reset link to your business official email address entered at the point of registration for next steps. '
			}
			authStatus={'Check your email'}
			btnText={'Back to Login'}
			icon={'mailbox'}
			handleClick={handleClick}
		/>
	);
};

export default ForgotPasswordMail;
