import React, { useEffect, useState } from 'react';
import Input from '../../../components/forms/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../components/Button';
import { updateUserPassword } from '../../../features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const Security = () => {
	const [upperCase, setUpperCase] = useState(false);
	const [lowerCase, setLowerCase] = useState(false);
	const [special, setSpecial] = useState(false);
	const [length, setLength] = useState(false);
	const [isPasswordField, setIsPasswordField] = useState(false);
	const { updateLoading } = useSelector((store) => store.user);
	const dispatch = useDispatch();
	const schema = Yup.object({
		password: Yup.string().required('Required'),
		newPassword: Yup.string()
			.required('Required')
			.min(8, 'Password must be at least 8 characters')
			.matches(
				/^(?=.*[a-z])/,
				'Password must contain at least one lowercase letter'
			)
			.matches(
				/^(?=.*[A-Z])/,
				'Password must contain at least one uppercase letter'
			)
			.matches(/^(?=.*[!@#$%^&*_-])/, 'Must Contain One Special Character'),
	});

	const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
		useFormik({
			initialValues: {
				password: '',
				newPassword: '',
			},
			validationSchema: schema,
			onSubmit: (values) => {
				dispatch(
					updateUserPassword({
						currentPassword: values.password,
						newPassword: values.newPassword,
					})
				);
			},
		});
	useEffect(() => {
		if (values.newPassword) {
			setUpperCase(/[A-Z]/g.test(values.newPassword));
			setLowerCase(/[a-z]/g.test(values.newPassword));
			setSpecial(/[!@#$%^&*_-]/g.test(values.newPassword));
			setLength(values.newPassword.length >= 8);
		}
		if (values.newPassword === '') {
			setUpperCase(false);
			setLowerCase(false);
			setSpecial(false);
			setLength(false);
		}
	}, [values.newPassword]);
	return (
		<div className="settings__container">
			<div className="settings__container--header">
				<h2>Change Password</h2>
				<p>
					If you want to change your old password to a new one, you can do so
					below.
				</p>
			</div>
			<div className="settings__container--body">
				<form className="settings_form" onSubmit={handleSubmit}>
					<Input
						type="password"
						id="password"
						value={values.password}
						onChange={handleChange}
						onBlur={handleBlur}
						label="Old Password"
						name="password"
						error={
							touched.password && errors.password ? (
								<small className="error">{errors.password}</small>
							) : null
						}
					/>
					<Input
						type="password"
						id="newPassword"
						placeholder="Create Password"
						label="New Password"
						name="newPassword"
						value={values.newPassword}
						onChange={handleChange}
						onBlur={handleBlur}
						onFocus={() => setIsPasswordField(true)}
						error={
							touched.newPassword && errors.newPassword ? (
								<small className="error">{errors.newPassword}</small>
							) : null
						}
					/>
					{isPasswordField && (
						<div className="password-rules">
							<div className="lineIndicator">
								<div className={`div ${length ? 'passed' : ''}`}></div>
								<div className={`div ${upperCase ? 'passed' : ''}`}></div>
								<div className={`div ${lowerCase ? 'passed' : ''}`}></div>
								<div className={`div ${special ? 'passed' : ''}`}></div>
							</div>
							<div className="actualRules">
								<div className={`rule ${length ? 'active' : ''}`}>
									<div className="circle"></div>
									<p>8 or more characters</p>
								</div>
								<div className={`rule ${upperCase ? 'active' : ''}`}>
									<div className="circle"></div>
									<p>An uppercase letter</p>
								</div>
								<div className={`rule  ${lowerCase ? 'active' : ''}`}>
									<div className="circle"></div>
									<p>A lowercase letter</p>
								</div>
								<div className={`rule  ${special ? 'active' : ''}`}>
									<div className="circle "></div>
									<p>A symbol (=!@#&$%)</p>
								</div>
							</div>
						</div>
					)}

					<Button
						variant="primary"
						type="submit"
						className="mt-32"
						block
						disabled={updateLoading}
					>
						{updateLoading ? 'Updating...' : 'Update'}
					</Button>
				</form>
			</div>
		</div>
	);
};

export default Security;
