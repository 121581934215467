import React from 'react';
import { useSelector } from 'react-redux';

const PlanStatusCard = ({
	status,
	statusName,
	numberOfPlan,
	total,
	isRemider,
	remindEmployeeBenefit,
}) => {
	const { createPlanLoading } = useSelector((store) => store.plan);
	return (
		<div className="plan__status--container">
			<div className="status">
				<p
					className={`${
						status === 'active'
							? 'active'
							: status === 'pending'
							? 'pending'
							: status === 'inactive'
							? 'inactive'
							: 'expired'
					}`}
				>
					{status}
				</p>
			</div>
			<h4>{statusName}</h4>
			<div className="__details">
				<p>
					<span>{numberOfPlan}</span> out of {total}
				</p>
				{isRemider && (
					<span onClick={() => remindEmployeeBenefit()}>
						{createPlanLoading ? 'Sending...' : 'send reminder'}
					</span>
				)}
			</div>
		</div>
	);
};

export default PlanStatusCard;
