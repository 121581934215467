import React from 'react';
import Logo from '../assets/svgs/logo.svg';
import Icon from '../components/Icon';

export default function Page404() {
	const isAuthenticated = JSON.parse(localStorage.getItem('user'));
	return (
		<div className="pagenotfound">
			<nav className="topnav">
				<div className="topnav--wrapper">
					<div className="brand">
						<img src={Logo} alt="Flance Logo" />
					</div>
				</div>
			</nav>
			<div className="pagenotfound__content">
				<Icon id={'404'} className="icon_404" />
				<p>
					Sorry, we can’t seem to find the page you’re looking for. Please try
					retyping the address or go back to our home.
				</p>
				<div className="__button">
					<a
						className="btn btn--primary"
						href={
							isAuthenticated && isAuthenticated.jwt ? '/plan' : '/auth/login'
						}
					>
						Go back home
					</a>
				</div>
			</div>
		</div>
	);
}
