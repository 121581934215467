import React from 'react';
import { planFilters } from '../../../utils/helpers';

const PlanHeader = ({ setActiveFilter, activeFilter, stringParams }) => {
	return (
		<div className="plan__header">
			<h1>
				{stringParams === 'new-benefit'
					? 'Add a new benefit to your plan'
					: 'Curate Plan'}
			</h1>
			<p>Pick benefits that suit the needs of your employees.</p>
			<div className="__filter">
				<ul>
					{planFilters.map((plan) => (
						<li
							key={plan.id}
							onClick={() => setActiveFilter(plan.filterType)}
							className={`${activeFilter === plan.filterType ? 'active' : ''}`}
						>
							{plan.filterName}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default PlanHeader;
