import React from 'react';
import Icon from './Icon';

const Pagination = ({ employees, page, setPage }) => {
	const nextPage = () => {
		setPage((oldPage) => {
			let nextPage = oldPage + 1;
			if (nextPage > employees.length - 1) {
				nextPage = 0;
			}
			return nextPage;
		});
	};
	const prevPage = () => {
		setPage((oldPage) => {
			let prevPage = oldPage - 1;
			if (prevPage < 0) {
				prevPage = employees.length - 1;
			}
			return prevPage;
		});
	};
	const handlePage = (index) => {
		setPage(index);
	};
	return (
		<div className="employee__table--pagination">
			<p className="pageNumber">
				{`page ${page + 1 ? page + 1 : 1} of ${
					employees?.length ? employees?.length : '1'
				}`}
			</p>
			<div className="pages">
				<div className="prev">
					<Icon width="9" height="14" id="left-caret" />
					<span onClick={prevPage}>Prev</span>
				</div>
				<div className="numbers">
					{employees?.map((item, index) => {
						return (
							<span
								key={index}
								className={`${index === page ? 'active' : null}`}
								onClick={() => handlePage(index)}
							>
								{index + 1}
							</span>
						);
					})}
				</div>
				<div className="next">
					<span onClick={nextPage}>NEXT</span>
					<Icon width="9" height="14" id="right-caret" />
				</div>
			</div>
		</div>
	);
};

export default Pagination;
