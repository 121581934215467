import React from 'react';
import { Modal } from '../Modal';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import {
	getIndividualTransaction,
	removeEmployeeFromPlan,
} from '../../features/plan/planSlice';

const ConfirmRemoveEmployee = ({
	confirmRemoveEmployeeModal,
	setConfirmRemoveEmployeeModal,
	planDetailFilter,
	queryId,
	currentEmployee,
}) => {
	const dispatch = useDispatch();
	const { planPaymentLoading } = useSelector((store) => store.plan);
	const handleClick = (id) => {
		dispatch(
			removeEmployeeFromPlan({
				planId: planDetailFilter?.planId,
				employeeId: id,
			})
		).then(() => {
			dispatch(getIndividualTransaction(queryId));
			setConfirmRemoveEmployeeModal(false);
		});
	};
	return (
		<Modal
			title="Remove Employees"
			show={confirmRemoveEmployeeModal}
			handleClose={() => setConfirmRemoveEmployeeModal(false)}
		>
			<div className="modal__content">
				<p
					style={{
						maxWidth: '100%',
						margin: 'auto',
						lineHeight: '1.5',
					}}
					className="co-gray"
				>
					Are you sure you want{' '}
					<b>{`${currentEmployee?.firstname} ${currentEmployee?.lastname}`} </b>{' '}
					from this plan{' '}
				</p>
				<Button
					variant="primary"
					className="btn--block mt-16"
					disabled={planPaymentLoading}
					onClick={() => handleClick(currentEmployee?.employeeId)}
				>
					{planPaymentLoading ? 'Removing...' : 'Remove Employee'}.
				</Button>
			</div>
		</Modal>
	);
};

export default ConfirmRemoveEmployee;
