import React, { useState } from 'react';
import Icon from '../Icon';
import Table from './Table';
import { useDispatch, useSelector } from 'react-redux';
import { resendInvitationLink } from '../../features/employees/employeesSlice';
import { useNavigate } from 'react-router-dom';

const EmployeesTable = ({
	// setDeactivateEmployeeModal,
	setDeleteEmployeeModal,
	columns,
	data,
	setCurrentEmployee,
}) => {
	const [currentEmployeeAction, setCurrentEmployeeAction] = useState(null);
	const navigate = useNavigate();
	const handleEmployeeAction = (id) => {
		setCurrentEmployeeAction(id);
	};
	const { addEmployeeLoading } = useSelector((store) => store.employees);
	const dispatch = useDispatch();
	return (
		<Table columns={columns}>
			{data?.map((row) => (
				<tr key={row?.employeeId}>
					<td>
						<div className="topnav-right d-iflx al-i-c">
							<div className="avatar-circle table_circle">
								<div className="avatar-circle__placeholder iflx-center-center">
									<p className="avatar-circle__initials iflx-center-center">
										{`${row?.firstname?.charAt(0)}${row?.lastname?.charAt(0)}`}
									</p>
								</div>
							</div>
							<div className="d-iflx al-i-c">
								<p className="mr-8">{`${row?.firstname} ${row?.lastname}`}</p>
							</div>
						</div>
					</td>
					<td>{row?.email}</td>
					<td>{row?.planName ? row?.planName : 'No Plans'}</td>
					<td>
						<span
							className={`status ${
								row?.status === 'ACTIVE'
									? 'employee-active'
									: row?.status === 'INACTIVE'
									? 'employee-inactive'
									: 'pending'
							}`}
						>
							{row?.status}
						</span>
					</td>
					<td
						onMouseOver={() => handleEmployeeAction(row?.employeeId)}
						onMouseLeave={() => handleEmployeeAction(null)}
					>
						<Icon width="4.5px" height="15.5px" id="three-dot" />
						{currentEmployeeAction === row?.employeeId && (
							<ul className="employee__table--dropdown">
								<li
									onClick={() => {
										navigate(`/employees/${row?.employeeId}`);
									}}
								>
									<span className="">View Details</span>
								</li>
								{row?.status !== 'ACTIVE' && (
									<li
										onClick={() =>
											dispatch(
												resendInvitationLink({
													email: row?.email,
													callBackUrl: `${window.location.origin}/auth/employee-signup`,
												})
											)
										}
									>
										<span className="">
											{addEmployeeLoading ? 'Sending...' : 'Resend Invitation'}
										</span>
									</li>
								)}

								<li
									onClick={() => {
										setCurrentEmployee(row);
										setDeleteEmployeeModal(true);
									}}
								>
									<span className="logoutBtn">Delete employee</span>
								</li>
							</ul>
						)}
					</td>
				</tr>
			))}
		</Table>
	);
};

export default EmployeesTable;
