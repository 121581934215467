import React, { useEffect, useState } from 'react';
import { Modal } from '../Modal';
import Icon from '../Icon';
import { convertBytesToKBAndMB } from '../../utils/helpers';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSignedUrl, submitCaCfile } from '../../features/user/userSlice';
import axios from 'axios';
import toast from 'react-hot-toast';

const CACmodal = ({
	openUploadCacModal,
	setOpenUploadCacModal,
	setIsUploadSuccess,
}) => {
	const [fileSelected, setFileSelected] = useState(null);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { signedUrl } = useSelector((store) => store.user);
	const notifyError = (message) => toast.error(message);
	const [errMsg, setErrMsg] = useState('');

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const fileSizeInKB = file.size / 1024;
			const sizeLimitKB = 5000; // Set your size limit in KB

			if (fileSizeInKB > sizeLimitKB) {
				setErrMsg('File size exceeds the limit (max: 5mb)');
				setFileSelected(null);
			} else {
				setErrMsg('');
				setFileSelected(file);
			}
		}
		// setFileSelected(file);
	};

	useEffect(() => {
		dispatch(fetchSignedUrl());
	}, []);

	const handleSubmit = async () => {
		const formData = new FormData();
		formData.append('pdfFile', fileSelected);
		setLoading(true);

		try {
			const resp = await axios.put(signedUrl, formData);
			if (resp?.status === 200) {
				dispatch(
					submitCaCfile({
						cac: signedUrl?.split('?')[0],
					})
				);
				setIsUploadSuccess(true);
				setOpenUploadCacModal(false);
				setLoading(false);
			}
		} catch (err) {
			notifyError(err?.response?.data?.message);
			setLoading(false);
		}
	};

	return (
		<Modal
			title="Upload your CAC"
			show={openUploadCacModal}
			handleClose={() => setOpenUploadCacModal(false)}
		>
			<label className={'card-check'}>
				<input
					className="card-check__option"
					type="file"
					name="upload_file"
					onChange={handleFileChange}
					accept=".pdf"
					// disabled={option?.disabled}
				/>
				<div
					className="card-check__value"
					style={{ backgroundColor: '#FFF3F3' }}
				>
					{/* <span className="card-check__checkmark"></span> */}
					<div className="card-check__value-text">
						{fileSelected ? (
							<p className="card-check__value-title">
								<Icon width="11" height="14" id="file" />
								{fileSelected?.name}
							</p>
						) : (
							<p className="card-check__value-title">
								Click to upload your file
							</p>
						)}

						<p className="card-check__value-subtext">
							{fileSelected ? (
								<>File Size: {convertBytesToKBAndMB(fileSelected?.size)}</>
							) : (
								<>{errMsg ? errMsg : 'PDF only. Max size is 5MB'}</>
							)}
						</p>
					</div>
				</div>
			</label>
			<div className="tip__container">
				<Icon id="tip" width="30" height="16" />
				<p>
					Your CAC document is your Certificate of Incorporation given as
					evidence of your existence as a company.
				</p>
			</div>

			<Button
				variant="primary"
				className="btn--block mt-16"
				disabled={!fileSelected}
				onClick={() => handleSubmit()}
			>
				{loading ? 'Uploading...' : 'Upload'}
			</Button>
		</Modal>
	);
};

export default CACmodal;
