import React, { useEffect } from 'react';
import AuthStatus from '../../../components/AuthStatus';
import { useNavigate } from 'react-router-dom';
// import { verifyUser } from '../../../features/user/userSlice';
import { useDispatch } from 'react-redux';
import { clearUser } from '../../../features/user/userSlice';
const VerifyMail = () => {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate('/auth/login');
	};
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(clearUser());
	}, []);

	return (
		<AuthStatus
			authDetails={
				"We have sent a verification link to your business's official email address entered at the point of registration for the next steps. An email has also been sent to your contact person."
			}
			authStatus={'Verify your official email'}
			btnText={'Back to Login'}
			icon={'mailbox'}
			handleClick={handleClick}
		/>
	);
};

export default VerifyMail;
