import React from 'react';
import Table from './Table';
import { useNavigate } from 'react-router-dom';
import { addPlanDetails } from '../../features/plan/planSlice';
import { useDispatch } from 'react-redux';
import { dateFormat, fmtCurrency } from '../../utils/helpers';

const PlanListTable = ({ data }) => {
	const columns = [
		'Plan',
		'Amount',
		'renewal date',
		'renewal Period',
		'No.  of Employee',
		'Status',
	];
	const Navigate = useNavigate();
	const dispatch = useDispatch();
	return (
		<Table columns={columns}>
			{data?.map((row) => (
				<tr
					className="pointer grey-bg"
					onClick={() => {
						dispatch(addPlanDetails(row));
						Navigate(`/plan/plans-details/${row?.planId}`);
					}}
					key={row.planId}
				>
					<td className="plan-table ">{row?.planName}</td>
					<td className="plan-table">{fmtCurrency(row?.amount)}</td>
					<td className="plan-table">
						{dateFormat(row?.endDate, 'do MMM, yyyy' || '--')}
					</td>
					<td className="plan-table duration">{row?.duration}</td>
					<td className="plan-table">{row?.numberOfEmployee}</td>
					<td className="plan-table">
						<span
							className={`status ${
								row?.status === 'ACTIVE'
									? 'employee-active'
									: row?.status === 'EXPIRED'
									? 'employee-inactive'
									: 'pending'
							}`}
						>
							{row?.status}
						</span>
					</td>
				</tr>
			))}
		</Table>
	);
};

export default PlanListTable;
