import React, { useEffect, useState } from 'react';
import Icon from '../../../components/Icon';
import { useDispatch, useSelector } from 'react-redux';
import {
	activateBusinessCard,
	deactivateBusinessCard,
	getBusinessCardDetails,
} from '../../../features/plan/planSlice';
import CardActionModal from '../../../components/modals/CardActionModal';
import Spinner from '../../../components/PageLoader';

const Card = () => {
	const { cardDetails, planPaymentLoading } = useSelector(
		(store) => store.plan
	);
	const dispatch = useDispatch();
	const [cardActivate, setCardActivate] = useState(false);
	const [cardDeactivate, setCardDeactivate] = useState(false);

	useEffect(() => {
		dispatch(getBusinessCardDetails());
	}, []);

	return (
		<div className="settings__container">
			<div className="settings__container--header">
				<h2>My Cards</h2>
				<p>Securely manage all your debit cards for payment right here.</p>
			</div>
			{planPaymentLoading ? (
				<Spinner />
			) : (
				// {
				// 	cardDetails ? :<></>
				// }
				<div className="settings__container--body">
					{/* <p className="newcard">Add a new card</p> */}
					{cardDetails?.cardFirst6digits ? (
						<div className="card__container">
							<div className="card-details">
								<div className="numbers">
									<Icon width="32" height="24" id="masterCard" />
									<span>{cardDetails?.cardFirst6digits}****</span>
									{cardDetails?.cardTokenActive && (
										<span className="default">Default</span>
									)}
								</div>
								<span className="date">Expiry {cardDetails?.cardExpiry}</span>
							</div>
							<div className="card__options">
								{cardDetails?.cardTokenActive ? (
									<span
										onClick={() => {
											setCardDeactivate(true);
										}}
									>
										Pause
									</span>
								) : (
									<span
										onClick={() => {
											setCardActivate(true);
										}}
									>
										Set as default
									</span>
								)}
							</div>
						</div>
					) : (
						<></>
					)}
				</div>
			)}

			{cardActivate && (
				<CardActionModal
					cardModal={cardActivate}
					setCardModal={setCardActivate}
					headerText={`Set ${cardDetails?.cardFirst6digits}**** as default`}
					iconsvg={'card'}
					bodyText={
						'Tui dolorem ipsum quia excepteur sint occaecat cupidatat non proident, quisquam est qui dolorem ipsum quia,'
					}
					btnText={planPaymentLoading ? 'Setting....' : 'Set Card as Default'}
					handleClick={() => {
						dispatch(activateBusinessCard()).then(() => {
							dispatch(getBusinessCardDetails());
							setCardActivate(false);
						});
					}}
					btnVariant={'primary'}
				/>
			)}
			{cardDeactivate && (
				<CardActionModal
					cardModal={cardDeactivate}
					setCardModal={setCardDeactivate}
					headerText={`Deactivate ${cardDetails?.cardFirst6digits}****`}
					iconsvg={'redCard'}
					bodyText={
						'By deactivating this card, we would not longer be able to charge this card to make payment. You can always re-add for future use.'
					}
					btnText={planPaymentLoading ? 'Deleting....' : 'Delete my card'}
					btnVariant={'danger'}
					handleClick={() => {
						dispatch(deactivateBusinessCard()).then(() => {
							dispatch(getBusinessCardDetails());
							setCardDeactivate(false);
						});
					}}
				/>
			)}
		</div>
	);
};

export default Card;
