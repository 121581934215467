import React from 'react';
import Button from './Button';

const ActiveBenefits = ({
	icon,
	text,
	handleClick,
	btnText,
	centerHeight = true,
}) => {
	return (
		<div className={`no_activePlan ${centerHeight ? 'centerHeight' : ''}`}>
			<div className="no_activePlan--container">
				<img src={icon} alt="loading" />
				<p>{text}</p>
				<div className="btn_container">
					<Button
						variant="primary"
						type="submit"
						className="mt-32"
						block
						onClick={handleClick}
					>
						{btnText}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ActiveBenefits;
