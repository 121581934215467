import React from 'react';
import Table from './Table';
import Icon from '../Icon';

const EmployeesPlanTable = ({ data, handleDelete }) => {
	const columns = ['Employees', 'Email', ''];
	return (
		<Table columns={columns}>
			{data.map((row) => (
				<tr key={row.id}>
					<td className="plan-table">{`${row?.firstname} ${row?.lastname}`}</td>
					<td className="plan-table">{row?.email}</td>
					<td
						className="plan-table"
						onClick={() => handleDelete(row?.employeeId)}
					>
						<Icon width="16px" height="19px" id="delete" />
					</td>
				</tr>
			))}
		</Table>
	);
};

export default EmployeesPlanTable;
