import React from 'react';
import Icon from './Icon';

const AlertPopUp = () => {
	return (
		<div className="popUp-container">
			<Icon width="20px" height="16px" id="light" />
			<p>
				The additional transaction fee for this plan is{' '}
				<span>₦0.00 for the next two (2) months </span>afterwards you’ll be
				charged for the service we’re providing.
			</p>
		</div>
	);
};

export default AlertPopUp;
