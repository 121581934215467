import React, { useState } from 'react';
import Icon from '../../../components/Icon';
import CACmodal from '../../../components/modals/CACmodal';
import { useSelector } from 'react-redux';
import RenewalPaymentModal from '../../../components/modals/RenewalPaymentModal';
import { useDispatch } from 'react-redux';
import { getBusinessDetails } from '../../../features/user/userSlice';

const Documents = () => {
	const [openUploadCacModal, setOpenUploadCacModal] = useState(false);
	const [isUploadSuccess, setIsUploadSuccess] = useState(false);
	const { businessDetails } = useSelector((store) => store.user);
	const dispatch = useDispatch();

	return (
		<div className="settings__container">
			<div className="settings__container--header">
				<h2>CAC Document</h2>
				<p>
					Your CAC document is your Certificate of Incorporation given as
					evidence of your existence as a company.
				</p>
			</div>
			<div className="settings__container--body">
				<div className="cac__container">
					<div className="cac-details">
						<div className="upload">
							<span>
								<Icon width="24" height="24" id="upload" />
							</span>
							<div className="upload__text">
								<h4>Upload Identification Document</h4>
								<span>Add you Certificate of Incorporation</span>
							</div>
						</div>
					</div>
					{businessDetails?.kycs?.cac === 'COMPLETE' ? (
						<div className="upload__completed">completed</div>
					) : (
						<div className="upload__status">
							<span onClick={() => setOpenUploadCacModal(true)}>
								Upload Doc
							</span>
						</div>
					)}
				</div>
			</div>
			{openUploadCacModal && (
				<CACmodal
					openUploadCacModal={openUploadCacModal}
					setOpenUploadCacModal={setOpenUploadCacModal}
					setIsUploadSuccess={setIsUploadSuccess}
				/>
			)}
			<RenewalPaymentModal
				bodyText={
					"You have uploaded your CAC, certificate of incorporation, and it is now under review. We will notify you once it's done"
				}
				paymentSuccess={isUploadSuccess}
				title={'Addition successful'}
				handleClick={() => {
					setIsUploadSuccess(false);
					dispatch(getBusinessDetails());
				}}
			/>
		</div>
	);
};

export default Documents;
