import React from 'react';

const Tooltip = ({ children, tipText }) => {
	return (
		<span data-text={tipText} className="tooltip">
			{children}
		</span>
	);
};

export default Tooltip;
