import React, { useEffect, useRef, useState } from 'react';
// import Icon from 'components/Icon';
import Icon from '../Icon';

const Input = ({
	// label,
	// name,
	// type,
	// value,
	// handleChange,
	// // handleFocus,
	// // handleBlur,
	// placeHolder,
	// isFloatingLabel = true,
	// // autoComplete = 'Off',
	// isHelperText,
	// helperText,
	label,
	type = 'text',
	name,
	onChange,
	value = '',
	error,
	placeholder,
	min,
	max,
	// id,
	onClick,
	// isDisabled,
	onFocus,
	onBlur,
	// touched,
	// mask,
	// maskOptions,
	className,
	isDate = false,
	isFloatingLabel = true,
	size,
	disabled = false,
	onKeyDown,
	isHelperText,
	helperText,
}) => {
	// const inputRef = useRef(null);
	// const labelRef = useRef(null);
	// useEffect(() => {
	// 	if (value !== '') {
	// 		labelRef.current.classList.add('active');
	// 	}
	// }, [value]);
	// const handleFocus = () => {
	// 	labelRef.current.classList.add('active');

	// 	// if (onFocus) {
	// 	// 	onFocus(e);
	// 	// }
	// 	// if (isDate === true) {
	// 	// 	inputRef.current.type = 'date';
	// 	// }
	// };

	// const handleBlur = (e) => {
	// 	if (e.target.value === '') {
	// 		labelRef.current.classList.remove('active');
	// 	} else {
	// 		labelRef.current.classList.add('active');
	// 	}

	// 	// try {
	// 	// 	onBlur && onBlur(e);
	// 	// 	if (isDate === true) {
	// 	// 		inputRef.current.type = 'text';
	// 	// 	}
	// 	// } catch (onBlurError) {
	// 	// 	//
	// 	// }
	// };
	const [isVisible, setIsVisible] = useState(false);
	const inputRef = useRef(null);
	const labelRef = useRef(null);

	useEffect(() => {
		if (value !== '') {
			labelRef.current.classList.add('active');
		}
	}, [value]);

	const handleFocus = (e) => {
		labelRef.current.classList.add('active');

		if (onFocus) {
			onFocus(e);
		}
		if (isDate === true) {
			inputRef.current.type = 'date';
		}
	};

	const handleBlur = (e) => {
		if (e.target.value === '') {
			labelRef.current.classList.remove('active');
		} else {
			labelRef.current.classList.add('active');
		}

		try {
			onBlur && onBlur(e);
			if (isDate === true) {
				inputRef.current.type = 'text';
			}
		} catch (onBlurError) {
			//
		}
	};
	// return (
	// 	<div className="form-group">
	// 		<div
	// 			className={`
	//         ${isFloatingLabel ? 'floating-label' : ''}
	//        `}
	// 			ref={labelRef}
	// 		>
	// 			<label htmlFor={label}>{label}</label>
	// 			<input
	// 				className="form-control"
	// 				type={type}
	// 				name={name}
	// 				value={value}
	// 				onChange={handleChange}
	// 				onFocus={handleFocus}
	// 				onBlur={handleBlur}
	// 				placeholder={placeHolder}

	// 				// autoComplete={autoComplete}
	// 			/>
	// 			<small className={`${isHelperText ? 'helperText' : ''}`}>
	// 				{helperText}
	// 			</small>
	// 		</div>
	// 	</div>
	// );
	return (
		<div className={`form-group`}>
			<div
				className={`
            ${isFloatingLabel ? 'floating-label' : ''} 
            ${className ? className : ''}`}
				ref={labelRef}
			>
				{size !== 'small' && <label htmlFor={label}>{label}</label>}
				<input
					type={isVisible ? 'text' : type}
					className={`form-control ${
						size === 'small' ? 'form-control--sm' : ''
					}`}
					min={min}
					max={max}
					name={name}
					onChange={onChange}
					value={value}
					ref={inputRef}
					data-placeholder={placeholder}
					onFocus={handleFocus}
					onBlur={handleBlur}
					onClick={onClick}
					autoComplete={value}
					// disabled={isDisabled || disabled}
					disabled={disabled}
					onKeyDown={onKeyDown}
				/>
				{size !== 'small' && (
					<span className="input-icon" id="toggle-icon">
						{type === 'password' && (
							<Icon
								id={isVisible ? 'eye-icon' : 'eye-icon-closed'}
								width="16"
								height={isVisible ? '15' : '13'}
								onClick={() => setIsVisible((visible) => !visible)}
							/>
						)}
					</span>
				)}
				<small className={`${isHelperText ? 'helperText' : ''}`}>
					{helperText}
				</small>
			</div>
			{error}
		</div>
	);
};

export default Input;
