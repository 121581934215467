import React from 'react';
import Table from './Table';
import { dateFormat, fmtCurrency } from '../../utils/helpers';

const WalletTable = ({ data, columns }) => {
	return (
		<Table columns={columns}>
			{data?.map((row, index) => (
				<tr key={index}>
					<td className="plan-table ">{`${dateFormat(
						row?.dateCreated,
						'MMM do, yyyy HH:mm' || '--'
					)}`}</td>
					<td className="plan-table">{row?.transactionType}</td>
					<td className="plan-table">{fmtCurrency(row?.amount)}</td>
					<td className="plan-table">{row?.transactionId}</td>
					<td className="plan-table">{row?.operationType}</td>
				</tr>
			))}
		</Table>
	);
};

export default WalletTable;
