import React from 'react';
import Icon from './Icon';
import Tooltip from './Tooltip';

import flanceLogo from '../assets/svgs/flance.svg';

import axaLogo from '../assets/svgs/axa.svg';
import cowrywiseLogo from '../assets/svgs/cowrywise.svg';
import hygeiaLogo from '../assets/svgs/hygeia.svg';
import octamile from '../assets/images/octamile.jpeg';
import peswa from '../assets/images/peswa.jpeg';
import gym from '../assets/images/gyms.svg';
import { fmtCurrency } from '../utils/helpers';

const PlansCard = ({
	key,
	company,
	name,
	type,
	tier,
	price,
	handleSelectedPlans,
	plansSelected,
	handleSelectedDetails,
	stringParams,
	AddBenefitsToPlans,
	duration,
}) => {
	const selectImage = (img) => {
		if (img == 'AXA Mansard' || img == 'Axa Mansard') {
			return axaLogo;
		} else if (img == 'CowryWise' || img == 'Cowrywise') {
			return cowrywiseLogo;
		} else if (img == 'Octamile') {
			return octamile;
		} else if (img == 'Peswa') {
			return peswa;
		} else if (img == 'Hygeia') {
			return hygeiaLogo;
		} else if (img == 'IFitness') {
			return gym;
		} else {
			return flanceLogo;
		}
	};

	return (
		<div
			className={`plan__card ${company == 'Peswa' ? 'hidden' : ''}`}
			key={key}
		>
			<div className="plan__card--header">
				<img src={selectImage(company)} alt={company} />
				<div className="header__content">
					<h2>{name}</h2>
					<p>
						{company}{' '}
						<Tooltip
							tipText={
								'Includes health insurance, savings, gym membership, and pension'
							}
						>
							{type === 'flanceCurated' ? '(Benefits)' : ''}
						</Tooltip>
					</p>
				</div>
			</div>
			<div className="plan__card--body">
				<p>{tier ? tier : 'Premiun'}</p>
				<p className="price">
					{fmtCurrency(price)}
					<span>/{duration ? duration : 'Monthly'}</span>
				</p>
			</div>
			<div className="plan__card--bottom">
				{stringParams === 'new-benefit' ? (
					<button
						variant="primary"
						type="submit"
						// className=" "
						className="plan__btn"
						block
						onClick={AddBenefitsToPlans}
					>
						<span className="btn-text add-to-cart">Choose</span>
					</button>
				) : (
					<button
						variant="primary"
						type="submit"
						// className=" "
						className={`plan__btn ${
							plansSelected.includes(name) ? 'clicked' : ''
						}`}
						block
						disabled={plansSelected.includes(name) ? true : false}
						onClick={handleSelectedPlans}
					>
						<span className="btn-text add-to-cart">Choose</span>
						<span className="btn-text added">Added</span>

						<span className="cart-i">
							<Icon id="cart-blue" width="23" height="21" />
						</span>
						<span className="box-i">
							{plansSelected ? plansSelected?.length : '0'}
						</span>
					</button>
				)}

				<hr />
				<button
					variant="primary"
					type="submit"
					className="plan__btn"
					block
					onClick={handleSelectedDetails}
				>
					View details
				</button>
			</div>
		</div>
	);
};

export default PlansCard;
