import React from 'react';
import { Modal } from '../Modal';
import Icon from '../Icon';
import Button from '../Button';
import { useDispatch } from 'react-redux';
import {
	getAllEmployees,
	resetEmployeeFail,
	resetEmployeeSuccess,
} from '../../features/employees/employeesSlice';

const ModalResponse = ({ setAddEmployeeSuccess, addEmployeeSuccess }) => {
	const dispatch = useDispatch();
	const handleClick = () => {
		dispatch(resetEmployeeSuccess());
		dispatch(resetEmployeeFail());
		dispatch(getAllEmployees());
		setAddEmployeeSuccess(false);
	};
	return (
		<Modal
			show={addEmployeeSuccess}
			handleClose={() => setAddEmployeeSuccess(false)}
			isHeader={false}
		>
			<div className="employee-success tx-c">
				<span>
					<Icon id={'check'} width="64" height="64" />
				</span>

				<h2>Addition successful</h2>

				<p
					style={{
						maxWidth: '100%',
						margin: 'auto',
						lineHeight: '1.5',
					}}
					className="co-gray"
				>
					You have successfully added an employee. An email has been sent to the
					employee for easy onboarding.
				</p>
				<p className="mt-40 tx-c " style={{ width: '100%' }}>
					<Button
						variant="primary"
						className="btn--block"
						onClick={handleClick}
						// disabled={loader}
					>
						{/* {loader ? 'Sending...' : 'Resend email'} */}
						Done
					</Button>
				</p>
			</div>
		</Modal>
	);
};

export default ModalResponse;
