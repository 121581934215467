import React from 'react';
import Table from './Table';

const PlansEmployeeTable = ({
	data,
	setConfirmRemoveEmployee,
	setCurrentEmployee,
}) => {
	const columns = ['Employee name', 'Email', ''];

	return (
		<Table columns={columns}>
			{data?.map((row) => (
				<tr key={row?.employeeId}>
					<td>
						<div className="topnav-right d-iflx al-i-c">
							<div className="avatar-circle table_circle">
								<div className="avatar-circle__placeholder iflx-center-center">
									<p className="avatar-circle__initials iflx-center-center">
										{`${row?.firstname?.charAt(0)}${row?.lastname?.charAt(0)}`}
									</p>
								</div>
							</div>
							<div className="d-iflx al-i-c">
								<p className="mr-8">{`${row?.firstname} ${row?.lastname}`}</p>
							</div>
						</div>
					</td>
					<td>{row?.email}</td>
					<td
						className="delete-row"
						onClick={() => {
							setConfirmRemoveEmployee(true);
							setCurrentEmployee(row);
						}}
					>
						remove
					</td>
				</tr>
			))}
		</Table>
	);
};

export default PlansEmployeeTable;
