import React from 'react';
import Icon from './Icon';
import Button from './Button';

const AuthStatus = ({
	icon,
	authStatus,
	handleClick,
	authDetails,
	btnText,
}) => {
	return (
		<div className="auth__form--content tx-c">
			<span className="mail-icon">
				<Icon id={icon} width="64" height="64" />
			</span>
			<h3 className="mt-32 mb-16 tx-medium" style={{ fontSize: '20px' }}>
				{authStatus}
			</h3>
			<p
				style={{
					maxWidth: '520px',
					margin: 'auto',
					lineHeight: '1.5',
				}}
				className="co-gray"
			>
				{authDetails}
			</p>
			{btnText && (
				<p className="mt-32 tx-c extralinnk" style={{ width: '100%' }}>
					<Button
						variant="primary"
						className="btn--block"
						onClick={handleClick}
						// disabled={loader}
					>
						{/* {loader ? 'Sending...' : 'Resend email'} */}
						{btnText}
					</Button>
				</p>
			)}
		</div>
	);
};

export default AuthStatus;
