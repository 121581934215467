import React, { useEffect } from 'react';
// import exclamation from '../../../assets/svgs/exclamation.svg';
// import ToasterAlert from '../../../components/ToasterAlert';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPlans } from '../../../features/plan/planSlice';
import Spinner from '../../../components/PageLoader';
import { Toaster } from 'react-hot-toast';

const Plans = () => {
	const { isLoading } = useSelector((store) => store.plan);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAllPlans());
	}, []);
	if (isLoading) {
		return <Spinner />;
	}
	return (
		<div className={`plan `}>
			<div className="toaster__style">
				<Toaster position="top-right" reverseOrder={false} />
			</div>
			<Outlet />
		</div>
	);
};

export default Plans;
