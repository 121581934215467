import React, { useState, useEffect } from 'react';
import { Modal } from '../Modal';
import Icon from '../Icon';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPaymentFlase } from '../../features/plan/planSlice';

const PaymentSuccessModal = () => {
	const [openModal, setOpenModal] = useState(true);
	const [name, setName] = useState({});
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const handleClick = () => {
		dispatch(setPaymentFlase());
		localStorage.removeItem('selectedBenefits');
		localStorage.removeItem('plansSelected');
		navigate('/plan');
	};

	useEffect(() => {
		const result = localStorage.getItem('cycleName');
		const user = result ? JSON.parse(result) : null;
		setName(user);
	}, []);

	return (
		<Modal
			show={openModal}
			handleClose={() => setOpenModal(false)}
			isHeader={false}
		>
			<div className="employee-success tx-c">
				<span>
					<Icon id={'check'} width="64" height="64" />
				</span>

				<h2 className="payment-success">Plan creation successful!</h2>

				<p
					style={{
						maxWidth: '100%',
						margin: 'auto',
						lineHeight: '1.5',
					}}
					className="co-gray payment-success_txt"
				>
					You’ve successfully added <span>{name?.planName}</span> as a plan and
					your billings are <span>{name?.cycle}</span>. Also, a mail has been
					sent to notify your employee for next steps.
				</p>
				<p className="mt-40 tx-c " style={{ width: '100%' }}>
					<Button
						variant="primary"
						className="btn--block"
						onClick={handleClick}
						// disabled={loader}
					>
						{/* {loader ? 'Sending...' : 'Resend email'} */}
						Done
					</Button>
				</p>
			</div>
		</Modal>
	);
};

export default PaymentSuccessModal;
