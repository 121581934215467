import React, { useEffect } from 'react';
import { Modal } from '../Modal';
import Input from '../forms/Input';
import Button from '../Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Icon from '../Icon';
import { useDispatch, useSelector } from 'react-redux';
import { addEmployees } from '../../features/employees/employeesSlice';

const AddEmployeeModal = ({
	addEmployeeModal,
	setAddEmployeeModal,
	setAddEmployeeSuccess,
	setExisitingUserModal,
	setAddEmployeeDetails,
}) => {
	const { addEmployeeLoading, employeeCreateSuccess } = useSelector(
		(store) => store.employees
	);

	const dispatch = useDispatch();
	const schema = Yup.object({
		firstname: Yup.string().required('First name is required'),
		lastname: Yup.string().required('Last name is required'),
		email: Yup.string()
			.required('Email field is required')
			.email('Invalid email address'),
	});

	const { handleSubmit, handleChange, handleBlur, touched, values, errors } =
		useFormik({
			initialValues: {
				firstname: '',
				lastname: '',
				email: '',
			},
			validationSchema: schema,
			onSubmit: (values) => {
				dispatch(
					addEmployees({
						firstName: values.firstname,
						lastName: values.lastname,
						email: values.email,
						callBackUrl: `${window.location.origin}/auth/employee-signup`,
					})
				).then((res) => {
					if (res?.payload?.data === 201 && res?.error) {
						setAddEmployeeModal(false);
						setExisitingUserModal(true);
						setAddEmployeeDetails({
							firstName: values.firstname,
							lastName: values.lastname,
							email: values.email,
						});
					} else if (res?.error && !res?.payload?.data) {
						setAddEmployeeModal(false);
					}
					// if (res === 200) {
					// 	setAddEmployeeModal(false);
					// 	setAddEmployeeSuccess(true);
					// } else if (res?.error?.message === 'Rejected') {
					// 	setAddEmployeeModal(false);
					// 	setExisitingUserModal(true);
					// 	setAddEmployeeDetails({
					// 		firstName: values.firstname,
					// 		lastName: values.lastname,
					// 		email: values.email,
					// 	});
					// }
				});
			},
		});
	useEffect(() => {
		if (employeeCreateSuccess) {
			setAddEmployeeModal(false);
			setAddEmployeeSuccess(true);
		}
	}, [employeeCreateSuccess]);
	return (
		<Modal
			title={'Add Employee'}
			show={addEmployeeModal}
			handleClose={() => setAddEmployeeModal(false)}
		>
			<div className="modal__content">
				<form
					className="__form __form-signup"
					style={{ marginTop: '0px' }}
					onSubmit={handleSubmit}
				>
					<div className="inputgroup">
						<Input
							style={{ marginTop: '0px' }}
							type="text"
							id="firstname"
							label="First name"
							name="firstname"
							value={values.firstname}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder="First name"
							error={
								touched.firstname && errors.firstname ? (
									<small className="error">{errors.firstname}</small>
								) : null
							}
						/>
						<Input
							style={{ marginTop: '0px' }}
							type="text"
							id="lastname"
							label="Last name"
							name="lastname"
							value={values.lastname}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder="Last name"
							error={
								touched.lastname && errors.lastname ? (
									<small className="error">{errors.lastname}</small>
								) : null
							}
						/>
					</div>
					<Input
						type="email"
						id="email"
						label="Email address"
						name="email"
						value={values.email}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
					<div className="__form-signup-tip">
						<span>
							<Icon id={'tip'} width={'20'} height={'16'} />
						</span>

						<p>
							A notification will be sent to the employee for smooth onboarding
							into Flance.
						</p>
					</div>

					<Button
						variant="primary"
						className="btn--block mt-16"
						disabled={addEmployeeLoading}
					>
						{addEmployeeLoading ? 'Adding...' : 'Add Employee'}
					</Button>
				</form>
			</div>
		</Modal>
	);
};

export default AddEmployeeModal;
