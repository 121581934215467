import React, { useEffect, useState } from 'react';
import SearchAddContainer from '../../../components/SearchAddContainer';
import PlansEmployeeTable from '../../../components/table/PlansEmployeeTable';
import Pagination from '../../../components/Pagination';
import EmployeeActionModal from '../../../components/modals/EmployeeActionModal';
import BillingTable from '../../../components/table/BillingTable';
import Goback from '../../../components/Goback';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	getBillingHistory,
	getBusinessCardDetails,
	getIndividualTransaction,
	planPaymentForExisitingEmployee,
	planPaymentTransactions,
	planRenewal,
	planRenewalWithToken,
	remindEmployeeActivateBenefit,
} from '../../../features/plan/planSlice';
import {
	dateFormat,
	fmtCurrency,
	getDaysLeft,
	getQueryVariable,
	paginate,
} from '../../../utils/helpers';
import MobileTable from '../../../components/modals/MobileTable';
import ConfirmRemoveEmployee from '../../../components/modals/ConfirmRemoveEmployee';
import ConfirmPaymentWithToken from '../../../components/modals/ConfirmPaymentWithToken';
import RenewalPaymentModal from '../../../components/modals/RenewalPaymentModal';
import Icon from '../../../components/Icon';
import HealthDetails from '../../../components/HeathDetails';
import SavingDetails from '../../../components/SavingDetails';
import AddEmployeeToExisitingPlan from '../../../components/modals/AddEmployeeToExisitingPlan';
import ConfirmPaymentDetails from '../../../components/modals/ConfirmPaymentDetails';
import AddEmployeeModal from '../../../components/modals/AddEmployeeModal';
import ModalResponse from '../../../components/modals/ModalResponse';
import ConfirmExisitingUser from '../../../components/modals/ConfirmExisitingUser';
import GymDetails from '../../../components/GymDetails';
// import Table from '../../../components/table/Table';

const PlanDetails = () => {
	const columns = [
		'Name',
		'Cost of plan',
		'renewal date',
		'renewal Period',
		'Status',
	];
	const [deleteEmployee, setDeleteEmployee] = useState(false);
	const [employeeDetails, setEmployeeDetails] = useState('');
	const [employeeSearch, setEmployeeSearch] = useState('');
	const [paginatedPlanEmployee, setPaginatedPlanEmployee] = useState([]);
	const [paginatedTransactions, setPaginatedTransactions] = useState([]);
	const [confirmRemoveEmployeeModal, setConfirmRemoveEmployeeModal] =
		useState(false);
	const [paymentSuccess, setPaymentSuccess] = useState(false);
	const [hasCardModal, setHasCardModal] = useState(false);
	const [currentEmployee, setCurrentEmployee] = useState();
	const [newPlanEmployees, setNewPlanEmployees] = useState(null);
	const [newBillings, setNewBillings] = useState([]);
	const [page, setPage] = useState(0);
	const [billingPage, setBillingPage] = useState(0);
	const [openDetailsModal, setOpenDetailsModal] = useState(false);
	const [selectedDetails, setSelectedDetails] = useState({});
	const [addEmployeesToPlanModal, setAddEmployeesToPlanModal] = useState(false);
	const [employeeToRenew, setEmployeeToRenew] = useState();
	const [employeePaymentModal, setEmployeePaymentModal] = useState(false);
	const [addEmployeeModal, setAddEmployeeModal] = useState(false);
	const [addEmployeeSuccess, setAddEmployeeSuccess] = useState(false);
	const [exisitingUserModal, setExisitingUserModal] = useState(false);
	const [addEmployeeDetails, setAddEmployeeDetails] = useState({});

	const { id } = useParams();

	const {
		individualTransactions,
		billingHistory,
		allTransactions,
		cardDetails,
		planPaymentLoading,
	} = useSelector((store) => store.plan);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(planPaymentTransactions());
		dispatch(getIndividualTransaction(id));
		dispatch(getBillingHistory(id));
	}, [id]);

	const planDetailFilter = allTransactions?.data?.find((plans) => {
		if (Number(plans?.planId) === Number(id)) {
			return plans;
		}
	});

	const handleClick = () => {
		if (cardDetails?.cardTokenActive) {
			setHasCardModal(true);
		} else {
			dispatch(
				planRenewal({
					planTYpe: 'CUSTOM',
					redirectUrl: `${window.location.origin}/plan`,
					customizations: {
						title: `${planDetailFilter?.planName} Plan Payments`,
						logo: 'https://res.cloudinary.com/flance/image/upload/v1666684602/logo/logo_bg_nc1svk.png',
					},
					duration: 'MONTHLY',
					planId: id,
				})
			);
		}
	};

	const employeeFilter = individualTransactions?.filter((user) => {
		if (
			user?.firstname?.toLowerCase().includes(employeeSearch.toLowerCase()) ||
			user?.lastname?.toLowerCase().includes(employeeSearch.toLowerCase())
		) {
			return user;
		}
	});
	useEffect(() => {
		if (employeeFilter) {
			setPaginatedPlanEmployee(paginate(employeeFilter, 4));
		}
	}, [employeeFilter]);

	useEffect(() => {
		if (billingHistory) {
			setPaginatedTransactions(paginate(billingHistory, 4));
		}
	}, [billingHistory]);

	useEffect(() => {
		if (paginatedPlanEmployee) {
			setNewPlanEmployees(paginatedPlanEmployee[page]);
		}
	}, [paginatedPlanEmployee, page]);

	useEffect(() => {
		if (paginatedTransactions) {
			setNewBillings(paginatedTransactions[billingPage]);
		}
	}, [paginatedTransactions, billingPage]);

	// CHECK IF USER HAS CARD SAVED

	useEffect(() => {
		dispatch(getBusinessCardDetails());
	}, []);

	const handlePaymentWithToken = () => {
		dispatch(
			planRenewalWithToken({
				planTYpe: 'CUSTOM',
				redirectUrl: `${window.location.origin}/plan`,
				customizations: {
					title: `${planDetailFilter?.planName} Plan Payments`,
					logo: 'https://res.cloudinary.com/flance/image/upload/v1666684602/logo/logo_bg_nc1svk.png',
				},
				duration: 'MONTHLY',
				planId: id,
			})
		).then(({ payload }) => {
			// console.log(res);
			if (payload.status === 'successful' || payload.status === 'completed') {
				setPaymentSuccess(true);
				setHasCardModal(false);
			} else {
				setHasCardModal(false);
			}
		});
	};

	const viewDetails = (benefit) => {
		setOpenDetailsModal(true);
		setSelectedDetails(benefit);
	};

	const addEmployeesToPlan = (employee) => {
		setEmployeeToRenew(employee);
		setAddEmployeesToPlanModal(false);
		setEmployeePaymentModal(true);
	};

	// Exisiting Employee Payment
	const handleEmployeePayment = () => {
		dispatch(
			planPaymentForExisitingEmployee({
				planTYpe: 'CUSTOM',
				redirectUrl: window.location.href,
				customizations: {
					title: `${planDetailFilter?.planName} Plan Payments`,
					logo: 'https://res.cloudinary.com/flance/image/upload/v1666684602/logo/logo_bg_nc1svk.png',
				},
				duration: 'MONTHLY',
				planId: id,
				employeeEmail: employeeToRenew?.email,
			})
		);
	};

	// Payment alert
	useEffect(() => {
		const payload = {
			// employeeCallBackUrl: `${process.env.REACT_APP_FLANCE_APP_URL}login`,
			// businessCallBackUrl: `${window.location.origin}/auth/login`,
			status: getQueryVariable('status'),
			transactionReference: getQueryVariable('tx_ref'),
			transactionId: getQueryVariable('transaction_id'),
		};
		if (payload.status === 'successful' || payload.status === 'completed') {
			setPaymentSuccess(payload);
		}
	}, [getQueryVariable('status')]);

	// Remind EMployee to activate plan

	const remindEmployeeBenefit = (benefitId) => {
		dispatch(
			remindEmployeeActivateBenefit({
				planId: id,
				benefitId: benefitId,
			})
		);
	};

	const navigate = useNavigate();

	// Add Employee to company

	// Add all benefits to the plan

	// const sumBenefitPrices = (benefitsArray) => {
	// 	const totalBenefitPrice = benefitsArray.reduce(
	// 		(accumulator, currentBenefit) =>
	// 			accumulator + currentBenefit.benefitPrice,
	// 		0
	// 	);

	// 	return totalBenefitPrice;
	// };

	return (
		<>
			<div className="planDetails">
				<Goback />
				{getDaysLeft(planDetailFilter?.endDate) < 10 ? (
					<div className="renewal-banner">
						<p>
							{getDaysLeft(planDetailFilter?.endDate) < 0
								? `	Your ${planDetailFilter?.planName} plan has expired `
								: `	Your ${
										planDetailFilter?.planName
								  } plan will expire in ${getDaysLeft(
										planDetailFilter?.endDate
								  )} 
							days. Please renew to continue to stay covered!`}
						</p>
						<a className="btn btn--sm" onClick={() => handleClick()}>
							Pay now!
						</a>
					</div>
				) : (
					<></>
				)}

				<div className="table-container">
					<h2>Plan Info</h2>
					<table columns={columns}>
						<thead>
							<tr>
								{columns.map((column, index) => (
									<th key={index}>{column}</th>
								))}
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{planDetailFilter?.planName}</td>
								<td>{fmtCurrency(planDetailFilter?.amount)}</td>
								<td>
									{dateFormat(
										planDetailFilter?.endDate,
										'do MMM, yyyy' || '--'
									)}
								</td>
								<td>{planDetailFilter?.duration}</td>
								<td>
									<span
										className={`status ${
											planDetailFilter?.status === 'ACTIVE'
												? 'employee-active'
												: planDetailFilter?.status === 'EXPIRED'
												? 'employee-inactive'
												: 'pending'
										}`}
									>
										{planDetailFilter?.status}
									</span>
								</td>
							</tr>
						</tbody>
					</table>
					<div className="smaller__table">
						<MobileTable
							key={planDetailFilter?.planId}
							email={fmtCurrency(planDetailFilter?.amount)}
							employeeId={planDetailFilter?.employeeId}
							firstname={planDetailFilter?.planName}
							planName={planDetailFilter?.duration}
							renewalDate={dateFormat(
								planDetailFilter?.endDate,
								'do MMM, yyyy' || '--'
							)}
							threeDots={false}
							nameCircle={false}
						/>
					</div>
				</div>
				<div className="planDetails__benefits">
					<h4>
						Benefits <span> (Click each to see their details)</span>
					</h4>
					<div className="benefits__categoryies">
						{planDetailFilter?.benefits.map((benefit) => (
							<div className="category" key={benefit?.id}>
								<div className="category__name">
									<span>
										<Icon
											width="24"
											height="24"
											id={benefit?.benefitCategory}
										/>
									</span>
									<p>{benefit?.benefitName}</p>
								</div>
								<span onClick={() => viewDetails(benefit)}>See details</span>
							</div>
						))}
						<div
							className="add__benefits"
							onClick={() => {
								navigate(`/plan/all-plans?name=new-benefit&planId=${id}`);
							}}
						>
							<span>
								<Icon width="11" height="11" id="plus-sign" />
							</span>
							<p>Add a new benefit</p>
						</div>
					</div>
				</div>

				<div className="planDetails__employees">
					<h2>Employees ({individualTransactions?.length})</h2>
					<p>The employees associated to this plan.</p>
					<div className="add-employee">
						<SearchAddContainer
							searchInput={employeeSearch}
							changeInput={(e) => setEmployeeSearch(e.target.value)}
							btnTitle={'Add Employee'}
							handleCLick={() => setAddEmployeesToPlanModal(true)}
						/>
					</div>
					<div className="table-container">
						<PlansEmployeeTable
							data={newPlanEmployees}
							setCurrentEmployee={setCurrentEmployee}
							setConfirmRemoveEmployee={setConfirmRemoveEmployeeModal}
						/>
						<div className="smaller__table">
							{newPlanEmployees?.map((plan) => (
								<MobileTable
									key={plan?.employeeId}
									email={plan?.email}
									employeeId={plan?.employeeId}
									firstname={plan?.firstname}
									lastname={plan?.lastname}
									threeDots={false}
									planName={' '}
									deleteIcon={true}
									handleDelete={() => {
										setConfirmRemoveEmployeeModal(true);
										setCurrentEmployee(plan);
									}}
								/>
							))}
						</div>

						<Pagination
							employees={paginatedPlanEmployee}
							page={page}
							setPage={setPage}
						/>
					</div>
				</div>
				<div className="planDetails__billings">
					<h2>Billing History</h2>
					<p>Manage your billing information and invoices here.</p>
					<div className="table-container">
						<BillingTable data={newBillings} />
						<div className="smaller__table">
							{newBillings?.map((plan, index) => (
								<MobileTable
									key={index}
									email={plan?.transactionDate}
									firstname={plan?.invoiceDescription}
									renewalDate={fmtCurrency(plan?.amount)}
									threeDots={false}
									nameCircle={false}
									planName={' '}
								/>
							))}
						</div>

						<Pagination
							employees={paginatedTransactions}
							page={billingPage}
							setPage={setBillingPage}
						/>
					</div>
				</div>
				<EmployeeActionModal
					btnText={'Delete Employee'}
					confirmText={'Please type Seun Elegboja to confirm deletion.'}
					inputName={'Employee’s name'}
					inputValue={employeeDetails}
					mainText={
						'Deleting Sean Elegboja will permanently remove his/her data and their plans will no longer be linked to your organisation.'
					}
					setInputValue={setEmployeeDetails}
					setShow={() => setDeleteEmployee(false)}
					show={deleteEmployee}
					title={'Delete Employee'}
				/>
				<ConfirmPaymentWithToken
					confirmDetailsModal={hasCardModal}
					setConfirmDetailsModal={setHasCardModal}
					loading={planPaymentLoading}
					paymentDuration={'Monthly'}
					userName={planDetailFilter?.planName}
					userCard={cardDetails}
					amount={planDetailFilter?.amount}
					makePaymentWithToken={handlePaymentWithToken}
				/>
				<ConfirmPaymentDetails
					PlanName={planDetailFilter?.planName}
					amount={planDetailFilter?.amount / planDetailFilter?.numberOfEmployee}
					benefits={planDetailFilter?.benefits}
					setConfirmDetailsModal={setEmployeePaymentModal}
					loading={planPaymentLoading}
					userName={`${employeeToRenew?.firstname} ${employeeToRenew?.lastname}`}
					confirmDetailsModal={employeePaymentModal}
					makePayment={() => handleEmployeePayment()}
					name={'Employee Name'}
				/>

				<AddEmployeeToExisitingPlan
					addEmployeesToPlanModal={addEmployeesToPlanModal}
					addEmployeesToPlan={addEmployeesToPlan}
					setAddEmployeesToPlanModal={setAddEmployeesToPlanModal}
					handleCLick={() => setAddEmployeeModal(true)}
				/>

				<ConfirmRemoveEmployee
					confirmRemoveEmployeeModal={confirmRemoveEmployeeModal}
					setConfirmRemoveEmployeeModal={setConfirmRemoveEmployeeModal}
					planDetailFilter={planDetailFilter}
					queryId={id}
					currentEmployee={currentEmployee}
				/>

				<AddEmployeeModal
					addEmployeeModal={addEmployeeModal}
					setAddEmployeeModal={setAddEmployeeModal}
					setAddEmployeeSuccess={setAddEmployeeSuccess}
					setExisitingUserModal={setExisitingUserModal}
					setAddEmployeeDetails={setAddEmployeeDetails}
				/>
				<ConfirmExisitingUser
					exisitingUserModal={exisitingUserModal}
					setExisitingUserModal={setExisitingUserModal}
					addEmployeeDetails={addEmployeeDetails}
					setAddEmployeeSuccess={setAddEmployeeSuccess}
				/>

				<ModalResponse
					setAddEmployeeSuccess={setAddEmployeeSuccess}
					addEmployeeSuccess={addEmployeeSuccess}
				/>

				<RenewalPaymentModal
					title={'Plan creation successful!!'}
					bodyText={`You’ve successfully added an Employee  to the ${planDetailFilter?.planName} and your billings are Monthly. Also, a mail has been sent to notify your employee for next steps.`}
					handleClick={() => {
						setPaymentSuccess(false);
						navigate('/plan');
					}}
					paymentSuccess={paymentSuccess}
				/>

				{selectedDetails?.benefitCategory === 'Health Insurance' ? (
					<HealthDetails
						openDetailsModal={openDetailsModal}
						setOpenDetailsModal={setOpenDetailsModal}
						selectedDetails={selectedDetails}
						isBtn={false}
						numberOfEmployee={planDetailFilter?.numberOfEmployee}
						remindEmployeeBenefit={remindEmployeeBenefit}
						showCardDetails={true}
					/>
				) : selectedDetails?.benefitCategory === 'Savings' ? (
					<SavingDetails
						openDetailsModal={openDetailsModal}
						setOpenDetailsModal={setOpenDetailsModal}
						selectedDetails={selectedDetails}
						isBtn={false}
						numberOfEmployee={planDetailFilter?.numberOfEmployee}
						remindEmployeeBenefit={remindEmployeeBenefit}
						showCardDetails={true}
					/>
				) : selectedDetails?.benefitCategory === 'Gym' ? (
					<GymDetails
						openDetailsModal={openDetailsModal}
						setOpenDetailsModal={setOpenDetailsModal}
						selectedDetails={selectedDetails}
						isBtn={false}
						numberOfEmployee={planDetailFilter?.numberOfEmployee}
						remindEmployeeBenefit={remindEmployeeBenefit}
						showCardDetails={true}
					/>
				) : (
					<></>
				)}

				{paymentSuccess && <RenewalPaymentModal />}
			</div>
		</>
	);
};

export default PlanDetails;
