import React, { useEffect, useState } from 'react';
import { Modal } from '../Modal';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from '../../features/employees/employeesSlice';

const AddEmployeeToExisitingPlan = ({
	addEmployeesToPlanModal,
	setAddEmployeesToPlanModal,
	addEmployeesToPlan,
    handleCLick
}) => {
	const [employeeSearch, setEmployeeSearch] = useState('');
	// const [selectedEmployees, setSelectedEmployees] = useState([]);
	const [users, setUsers] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAllEmployees());
	}, []);
	const { employees } = useSelector((store) => store.employees);
	const employeeFilter = employees?.filter((user) => {
		if (!user?.planName) {
			if (
				user?.firstname?.toLowerCase().includes(employeeSearch.toLowerCase()) ||
				user?.lastname?.toLowerCase().includes(employeeSearch.toLowerCase())
			) {
				return user;
			}
		} else {
			return;
		}
	});
	useEffect(() => {
		setUsers(employeeFilter);
	}, [employeeFilter?.length]);

	// Might need for later

	// const handleChange = (e) => {
	// 	const { name, checked } = e.target;
	// 	if (name === 'selectAll') {
	// 		let tempUser = employeeFilter?.map((user) => {
	// 			return { ...user, isChecked: checked };
	// 		});
	// 		setSelectedEmployees(tempUser.filter((user) => user.isChecked));
	// 		setUsers(tempUser);
	// 	} else {
	// 		let tempUser = users.map((user) => {
	// 			return user?.employeeId == name
	// 				? { ...user, isChecked: checked }
	// 				: user;
	// 		});
	// 		setSelectedEmployees(tempUser.filter((user) => user.isChecked));
	// 		setUsers(tempUser);
	// 	}
	// };

	// console.log(selectedEmployees);
	return (
		<Modal
			handleClose={() => {
				setAddEmployeesToPlanModal(false);
			}}
			show={addEmployeesToPlanModal}
			title={'Invite Employee (s) '}
		>
			<div className="addToPlan">
				<div className="addToPlan__header">
					<div className="addToPlan__header--search">
						<input
							type="text"
							id="savingsAmount"
							value={employeeSearch}
							onChange={(e) => setEmployeeSearch(e.target.value)}
							placeholder="Search "
						/>
					</div>
					<div className="addToPlan__header--btn">
						<Button
							variant="primary"
							size="sm"
							className="btn--block"
							onClick={handleCLick}
							// disabled={loader}
						>
							New
						</Button>
					</div>
				</div>

				<div className="addToPlan__body">
					{/* <p>Select all</p> */}
					{/* <div className="select__all">
						<input
							type="checkbox"
							checked={!users?.some((user) => user?.isChecked !== true)}
							onChange={handleChange}
							name="selectAll"
						/>
						<label className="#636363 text-base">Select All</label>
					</div> */}
					{users.map((employee) => (
						<div
							className="__list"
							key={employee?.employeeId}
							onClick={() => addEmployeesToPlan(employee)}
						>
							<div className="name__details">
								<div className="avatar-circle table_circle">
									<div className="avatar-circle__placeholder iflx-center-center">
										<p className="avatar-circle__initials iflx-center-center">
											{`${employee?.firstname?.charAt(
												0
											)}${employee?.lastname?.charAt(0)}`}
										</p>
									</div>
								</div>

								<div className="name">
									<h4>{`${employee?.firstname} ${employee?.lastname}`}</h4>
									<p>{employee?.email}</p>
								</div>
							</div>
							{/* <input
								type="checkbox"
								name={employee?.employeeId}
								checked={employee?.isChecked || false}
								onChange={handleChange}
							/> */}
						</div>
					))}
				</div>
				{/* <div className="addToPlan__footer">
					<Button
						variant="primary"
						className="btn--block mt-16"
						// disabled={addEmployeeLoading}
					>
						Continue
					</Button>
				</div> */}
			</div>
		</Modal>
	);
};

export default AddEmployeeToExisitingPlan;
