import React, { useEffect, useState } from 'react';
import Icon from '../../../components/Icon';
import PlanHeader from './PlanHeader';
import PlanBody from './PlanBody';
import SavingsModal from '../../../components/modals/SavingsModal';
import Goback from '../../../components/Goback';
import Cart from '../../../components/Cart';
import SavingDetails from '../../../components/SavingDetails';
import HealthDetails from '../../../components/HeathDetails';
import { toast } from 'react-hot-toast';
import {
	benefitPaymentForExisitingPlan,
	planPaymentTransactions,
} from '../../../features/plan/planSlice';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmPaymentDetails from '../../../components/modals/ConfirmPaymentDetails';
import { getQueryVariable } from '../../../utils/helpers';
import { useNavigate } from 'react-router-dom';
import RenewalPaymentModal from '../../../components/modals/RenewalPaymentModal';
import ActiveBenefits from '../../../components/ActiveBenefits';
import skeletonLoading from '../../../assets/svgs/Notransactions.svg';

const AllPlans = () => {
	const searchParams = new URLSearchParams(location.search);
	const dispatch = useDispatch();
	const name = searchParams.get('name');
	const planId = searchParams.get('planId');
	const [activeFilter, setActiveFilter] = useState('all');
	const [selectedPlans, setSelectedPlans] = useState(() => {
		const storedItems = localStorage.getItem('selectedBenefits');
		return storedItems ? JSON.parse(storedItems) : [];
	});
	const [openCart, setOpenCart] = useState(false);
	const [savingsmodal, setSavingsModal] = useState(false);
	const [savingsAmount, setSavingsAmount] = useState();
	const [plansSelected, setPlansSelcted] = useState(() => {
		const storedItems = localStorage.getItem('plansSelected');
		return storedItems ? JSON.parse(storedItems) : [];
	});
	const [modalData, setModalData] = useState();
	const [selectedDetails, setSelectedDetails] = useState({});
	const [openDetailsModal, setOpenDetailsModal] = useState(false);
	const [singleBenefit, setSingleBenefit] = useState();
	const [benefitPaymentModal, setBenefitPaymentModal] = useState(false);
	const [paymentSuccess, setPaymentSuccess] = useState(false);
	useEffect(() => {
		dispatch(planPaymentTransactions());
	}, []);
	const notify = (message) => toast.error(message);
	const { allTransactions, planPaymentLoading, plans } = useSelector(
		(store) => store.plan
	);

	const planDetailFilter = allTransactions?.data?.find((plans) => {
		if (Number(plans?.planId) === Number(planId)) {
			return plans;
		}
	});

	const AddBenefitsToPlans = (plan) => {
		const hasHealthInsuranceBenefit = planDetailFilter?.benefits.some(
			(benefit) => benefit.benefitCategory === plan?.benefitCategory
		);

		// Show an alert message if benefit is found
		if (hasHealthInsuranceBenefit) {
			notify('Benefit category already exists');
		} else {
			setSingleBenefit(plan);
			setBenefitPaymentModal(true);
		}
	};

	const handleSelectedPlans = (plan) => {
		if (plan.planType === 'flanceCurated') {
			notify('You cannot add a curated plan to benefits selection');
		} else if (plan.planType === 'savings') {
			setSavingsModal(true);
			setModalData(plan);
		} else if (!selectedPlans.includes(plan)) {
			setSelectedPlans([...selectedPlans, plan]);
			setPlansSelcted([...plansSelected, plan.benefitName]);
		}
	};

	useEffect(() => {
		localStorage.setItem('selectedBenefits', JSON.stringify(selectedPlans));
		localStorage.setItem('plansSelected', JSON.stringify(plansSelected));
	}, [selectedPlans]);

	// Retrieve selected items from localStorage on component initialization
	useEffect(() => {
		const storedItems = localStorage.getItem('selectedBenefits');
		const storedBenefits = localStorage.getItem('plansSelected');
		if (storedItems) {
			setSelectedPlans(JSON.parse(storedItems));
			setPlansSelcted(JSON.parse(storedBenefits));
		}
	}, []);

	// Payment for adding new benefits to existing plans
	const handleNewBenefitPayment = () => {
		dispatch(
			benefitPaymentForExisitingPlan({
				planTYpe: 'CUSTOM',
				redirectUrl: window.location.href,
				customizations: {
					title: `${planDetailFilter?.planName} Plan Payments`,
					logo: 'https://res.cloudinary.com/flance/image/upload/v1666684602/logo/logo_bg_nc1svk.png',
				},
				duration: 'MONTHLY',
				planId: planId,
				benefitId: singleBenefit?.id,
				amount:
					singleBenefit?.benefitPrice * planDetailFilter?.numberOfEmployee,
			})
		);
	};

	// Payment alert
	useEffect(() => {
		const payload = {
			// employeeCallBackUrl: `${process.env.REACT_APP_FLANCE_APP_URL}login`,
			// businessCallBackUrl: `${window.location.origin}/auth/login`,
			status: getQueryVariable('status'),
			transactionReference: getQueryVariable('tx_ref'),
			transactionId: getQueryVariable('transaction_id'),
		};
		if (
			payload.status === 'successful' ||
			payload.status === 'completed' ||
			payload.status === 'success'
		) {
			setPaymentSuccess(payload);
		}
	}, [getQueryVariable('status')]);

	const navigate = useNavigate();

	const planFilter = plans?.filter((plan) => {
		if (plan?.benefitCategory === 'Gym' && !plan?.isCorporate) {
			return;
		}
		if (activeFilter === 'all') {
			return plan;
		} else if (activeFilter === 'Corporate Health Insurance') {
			if (plan?.benefitCategory === 'Health Insurance') {
				return plan?.isCorporate;
			}
		} else if (activeFilter === 'Health Insurance') {
			if (plan?.benefitCategory === 'Health Insurance') {
				return !plan?.isCorporate;
			}
		}
		return activeFilter == plan?.benefitCategory;
	});

	return (
		<>
			<Goback />
			<PlanHeader
				setActiveFilter={setActiveFilter}
				activeFilter={activeFilter}
				stringParams={name}
				planId={planId}
			/>
			{planFilter.length > 0 ? (
				<>
					<PlanBody
						selectedPlans={selectedPlans}
						setSavingsModal={setSavingsModal}
						setSelectedPlans={setSelectedPlans}
						plansSelected={plansSelected}
						setPlansSelcted={setPlansSelcted}
						setModalData={setModalData}
						setSelectedDetails={setSelectedDetails}
						setOpenDetailsModal={setOpenDetailsModal}
						handleSelectedPlans={handleSelectedPlans}
						planDetailFilter={planDetailFilter}
						stringParams={name}
						planId={planId}
						AddBenefitsToPlans={AddBenefitsToPlans}
						planFilter={planFilter}
					/>
					:
				</>
			) : (
				<div className="no__benefits">
					<ActiveBenefits
						icon={skeletonLoading}
						text={`Check back, plans would be available for you soon! Explore other benefits.`}
						handleClick={() => {
							setActiveFilter('all');
						}}
						btnText={`Explore benefits`}
						centerHeight={false}
					/>
				</div>
			)}

			{name === 'new-benefit' ? (
				<></>
			) : (
				<div className="cart" onClick={() => setOpenCart(true)}>
					<div className="cart__details">
						<Icon id="cart" width="23" height="21" />
						<p>{selectedPlans ? selectedPlans?.length : '0'}</p>
					</div>
				</div>
			)}

			<SavingsModal
				savingsmodal={savingsmodal}
				setSavingsModal={setSavingsModal}
				savingsAmount={savingsAmount}
				setSavingsAmount={setSavingsAmount}
				modalData={modalData}
				selectedPlans={selectedPlans}
				setSelectedPlans={setSelectedPlans}
				plansSelected={plansSelected}
				setPlansSelcted={setPlansSelcted}
			/>
			<Cart
				openCart={openCart}
				setOpenCart={setOpenCart}
				selectedPlans={selectedPlans}
				setSelectedPlans={setSelectedPlans}
				plansSelected={plansSelected}
				setPlansSelcted={setPlansSelcted}
			/>
			<ConfirmPaymentDetails
				PlanName={planDetailFilter?.planName}
				amount={
					singleBenefit?.benefitPrice * planDetailFilter?.numberOfEmployee
				}
				// benefits={singleBenefit?.benefitName}
				setConfirmDetailsModal={setBenefitPaymentModal}
				loading={planPaymentLoading}
				userName={singleBenefit?.benefitName}
				confirmDetailsModal={benefitPaymentModal}
				makePayment={() => handleNewBenefitPayment()}
				name={'Benefit Name'}
			/>
			{selectedDetails?.benefitCategory === 'Health Insurance' ? (
				<HealthDetails
					openDetailsModal={openDetailsModal}
					setOpenDetailsModal={setOpenDetailsModal}
					selectedDetails={selectedDetails}
					handleSelectedPlans={handleSelectedPlans}
					plansSelected={plansSelected}
				/>
			) : selectedDetails?.benefitCategory === 'Savings' ? (
				<SavingDetails
					openDetailsModal={openDetailsModal}
					setOpenDetailsModal={setOpenDetailsModal}
					selectedDetails={selectedDetails}
					handleSelectedPlans={handleSelectedPlans}
					plansSelected={plansSelected}
				/>
			) : (
				<></>
			)}
			<RenewalPaymentModal
				title={'Benefit Added successful!!'}
				bodyText={`You’ve successfully added a new Benefit  to  ${planDetailFilter?.planName} and your billings are Monthly. Also, a mail has been sent to notify your employee for next steps.`}
				handleClick={() => {
					setPaymentSuccess(false);
					navigate(`/plan/plans-details/${planId}`);
				}}
				paymentSuccess={paymentSuccess}
			/>
		</>
	);
};

export default AllPlans;
