import React from 'react';
import PlansCard from '../../../components/PlansCard';

const PlanBody = ({
	plansSelected,
	setSelectedDetails,
	setOpenDetailsModal,
	handleSelectedPlans,
	planDetailFilter,
	stringParams,
	planId,
	AddBenefitsToPlans,
	planFilter,
}) => {
	const handleSelectedDetails = (plan) => {
		setSelectedDetails(plan);
		setOpenDetailsModal(true);
	};

	return (
		<div className="plan__body">
			{planFilter?.map((plan) => (
				<PlansCard
					key={plan?.id}
					company={plan?.benefitProvider}
					name={plan?.benefitName}
					type={plan?.benefitCategory}
					tier={plan.planTier}
					price={plan?.defaultPrice ? plan?.defaultPrice : plan?.benefitPrice}
					handleSelectedPlans={() => handleSelectedPlans(plan)}
					plansSelected={plansSelected}
					handleSelectedDetails={() => handleSelectedDetails(plan)}
					planDetailFilter={planDetailFilter}
					stringParams={stringParams}
					planId={planId}
					AddBenefitsToPlans={() => AddBenefitsToPlans(plan)}
					duration={plan?.defaultDuration}
				/>
			))}
		</div>
	);
};

export default PlanBody;
