import React, { useEffect } from 'react';
import Goback from '../../../components/Goback';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeDetails } from '../../../features/employees/employeesSlice';
import Spinner from '../../../components/PageLoader';
import Icon from '../../../components/Icon';

const EmployeeDetail = () => {
	const { id } = useParams();
	const { addEmployeeLoading, employeeDetails, failedEmployeeDetails } =
		useSelector((store) => store.employees);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getEmployeeDetails(id));
	}, [id]);

	return (
		<div className="employee">
			<Goback />
			<>
				{addEmployeeLoading ? (
					<Spinner />
				) : (
					<>
						{failedEmployeeDetails ? (
							<p>Details for this employee not found</p>
						) : (
							<>
								<div className="employee__details--header">
									<h2>Employee Info</h2>
									<div className="info">
										<div className="initials__container">
											{`${employeeDetails?.data?.firstname?.charAt(
												0
											)}${employeeDetails?.data?.lastname?.charAt(0)}`}
										</div>
										<div className="name__details">
											<p className="name">{`${employeeDetails?.data?.firstname} ${employeeDetails?.data?.lastname}`}</p>
											<p className="email">{employeeDetails?.data?.email}</p>
											<p className="planName">
												{employeeDetails?.data?.planName}
											</p>
										</div>
									</div>
								</div>
								<div className="employee__details--body">
									<h2>Overview of details</h2>
									<div className="__overview">
										<p>Duration</p>
										<p>{employeeDetails?.data?.duration}</p>
									</div>
									<div className="__overview">
										<p>Benefit Assigned</p>
										<div className="benefits">
											{employeeDetails?.data?.benefits
												.split(', ')
												.map((benefit, index) => (
													<span key={index}>
														<span>
															<Icon width="24" height="24" id={benefit} />
														</span>
														{benefit}
													</span>
												))}
										</div>
									</div>
									<div className="__overview">
										<p>Total Billing</p>
										<p>{employeeDetails?.data?.totalBilling}</p>
									</div>
								</div>
							</>
						)}
					</>
				)}
			</>
		</div>
	);
};

export default EmployeeDetail;
