import React from 'react';
import { Modal } from '../Modal';
import Icon from '../Icon';
import Button from '../Button';
import { useDispatch } from 'react-redux';
import {
	deleteEmployeeReset,
	getAllEmployees,
} from '../../features/employees/employeesSlice';

const DeleteEmployeeSuccess = ({
	setRemoveEmployee,
	removeEmployee,
	currentEmployee,
}) => {
	const dispatch = useDispatch();
	const handleClick = () => {
		dispatch(deleteEmployeeReset());
		dispatch(getAllEmployees());
		setRemoveEmployee(false);
	};
	return (
		<Modal show={removeEmployee} handleClose={handleClick} isHeader={false}>
			<div className="employee-success tx-c">
				<span>
					<Icon id={'check'} width="64" height="64" />
				</span>

				<h2>Deletion successful</h2>

				<p
					style={{
						maxWidth: '100%',
						margin: 'auto',
						lineHeight: '1.5',
					}}
					className="co-gray"
				>
					You’ve successfully deleted{' '}
					<b>{`${currentEmployee?.firstname} ${currentEmployee?.lastname} `}</b>{' '}
					. You are no longer associated to their profile. .
				</p>
				<p className="mt-40 tx-c " style={{ width: '100%' }}>
					<Button
						variant="primary"
						className="btn--block"
						onClick={handleClick}
						// disabled={loader}
					>
						{/* {loader ? 'Sending...' : 'Resend email'} */}
						Done
					</Button>
				</p>
			</div>
		</Modal>
	);
};

export default DeleteEmployeeSuccess;
