import { configureStore } from '@reduxjs/toolkit';
import userSlice from './features/user/userSlice';
import employeesSlice from './features/employees/employeesSlice';
// import PlanSlice from './features/Plan/PlanSlice';
import planSlice from './features/plan/planSlice';
import walletSlice from './features/wallet/walletSlice';

export const store = configureStore({
	reducer: {
		user: userSlice,
		employees: employeesSlice,
		plan: planSlice,
		wallet: walletSlice,
	},
});
