import React from 'react';
import SideModal from './modals/SideModal';
import PlanStatusCard from './PlanStatusCard';

const GymDetails = ({
	openDetailsModal,
	setOpenDetailsModal,
	handleSelectedPlans,
	selectedDetails,
	plansSelected = '',
	isBtn = true,
	numberOfEmployee,
	remindEmployeeBenefit,
	showCardDetails = false,
}) => {
	return (
		<SideModal
			btnText={'Choose Benefit'}
			handleClick={() => {
				handleSelectedPlans(selectedDetails);
				setOpenDetailsModal(false);
			}}
			headerText={selectedDetails?.benefitName}
			openSideModal={openDetailsModal}
			setOpenSideModal={setOpenDetailsModal}
			isCart={false}
			btnDisbled={
				plansSelected.includes(selectedDetails?.benefitName) ? true : false
			}
			isBtn={isBtn}
		>
			{
				<div className="benefit__details">
					{showCardDetails && (
						<div className="plan__status">
							<PlanStatusCard
								isRemider={false}
								numberOfPlan={selectedDetails?.activeEmployeeCount}
								status={'active'}
								statusName={'ACTIVE EMPLOYEES'}
								total={numberOfEmployee}
							/>
							<PlanStatusCard
								isRemider={true}
								numberOfPlan={selectedDetails?.inactiveEmployeeCount}
								status={'inactive'}
								statusName={'inACTIVE EMPLOYEES'}
								total={numberOfEmployee}
								remindEmployeeBenefit={() =>
									remindEmployeeBenefit(selectedDetails?.id)
								}
							/>
						</div>
					)}

					<>
						<p>No description for this benefit yet</p>
					</>
				</div>
			}
		</SideModal>
	);
};

export default GymDetails;
