import React from 'react';
import { Modal } from '../Modal';
import Input from '../forms/Input';
import Button from '../Button';

const SavingsModal = ({
	savingsmodal,
	setSavingsModal,
	savingsAmount,
	setSavingsAmount,
	modalData,
	selectedPlans,
	setSelectedPlans,
	setPlansSelcted,
	plansSelected,
}) => {
	const handleSubmit = () => {
		modalData.planPrice = savingsAmount;
		setSavingsModal(false);
		setSelectedPlans([...selectedPlans, modalData]);
		setPlansSelcted([...plansSelected, modalData.planName]);
	};
	return (
		<Modal
			title={'Enter Savings Amount'}
			show={savingsmodal}
			handleClose={() => setSavingsModal(false)}
		>
			<div className="modal__content">
				<p className="main-text">
					Enter amount you’d like to save for each of your employee.
				</p>
				<Input
					type="number"
					id="savingsAmount"
					value={savingsAmount}
					onChange={(e) => setSavingsAmount(e.target.value)}
					// onBlur={handleBlur}
					label="Savings Amount"
					name="Savings Amount"
				/>

				<Button
					variant="primary"
					className="btn--block"
					onClick={handleSubmit}
					// disabled={loader}
				>
					{/* {loader ? 'Sending...' : 'Resend email'} */}
					continue plan creation
				</Button>
			</div>
		</Modal>
	);
};

export default SavingsModal;
