import React from 'react';
import Input from '../../components/forms/Input';
import Button from '../../components/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { forgotPassword } from '../../features/user/userSlice';

const ForgotPassword = () => {
	// const Navigate = useNavigate();
	const { isLoading } = useSelector((store) => store.user);
	const dispatch = useDispatch();
	const schema = Yup.object({
		email: Yup.string()
			.required('Email field is required')
			.email('Invalid email address'),
	});
	const { handleSubmit, handleChange, handleBlur, touched, values, errors } =
		useFormik({
			initialValues: {
				email: '',
			},
			validationSchema: schema,
			onSubmit: (values) => {
				dispatch(
					forgotPassword({
						email: values.email,
						resetLink: `${window.location.origin}/auth/reset-password`,
					})
				);
			},
		});
	return (
		<div className="auth__form--content">
			<div className="auth__form--header">
				<div className="header__container">
					<div className="header__container--content">
						<h3>Forgot Password</h3>
						<p>
							Enter your email associated with this account and we’ll send you a
							reset password link.
						</p>
					</div>
				</div>

				{/* <small className="error">{error}</small> */}
			</div>
			<form className="__form" onSubmit={handleSubmit}>
				<Input
					type="email"
					id="email"
					value={values.email}
					onChange={handleChange}
					onBlur={handleBlur}
					label="Business email"
					name="email"
					error={
						touched.email && errors.email ? (
							<small className="error">{errors.email}</small>
						) : null
					}
				/>

				<Button
					variant="primary"
					type="submit"
					className="mt-40"
					block
					disabled={isLoading}
				>
					{isLoading ? 'Loading...' : 'Reset password'}
				</Button>
			</form>
			<p className="mt-32 tx-c extralinnk">
				Already have a business account?{' '}
				<Link className="btn btn--link btn--link-secondary" to="/auth/login">
					Login
				</Link>
			</p>
		</div>
	);
};

export default ForgotPassword;
