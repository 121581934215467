import React, { useEffect, useState } from 'react';
import SideModal from './modals/SideModal';
import sanityClient from '../sanitySetup';
import Icon from './Icon';
import PlanStatusCard from './PlanStatusCard';
import Spinner from './PageLoader';
// import Spinner from './PageLoader';

const HealthDetails = ({
	setOpenDetailsModal,
	openDetailsModal,
	selectedDetails,
	handleSelectedPlans,
	plansSelected = '',
	isBtn = true,
	numberOfEmployee,
	remindEmployeeBenefit,
	showCardDetails = false,
}) => {
	const [post, setPost] = useState();
	const [loading, setLoading] = useState(false);
	// const [deatail, setDetails] = useState();

	useEffect(() => {
		setLoading(true);
		sanityClient
			.fetch(
				`*[_type == "health"]{
			_id,
			_ref,
			name,
			bio,
			regionCovered,
			outPatientLimitPrice,
			outPatientLimitDetails,
			inPatientLimitPrice,
			inPatientLimitDetails,
			generalExclusions,
			importantNotes,
			"allServices" : servicesCovered[] -> {
				services,
				subService
			}
			
		  }`
			)
			.then((data) => {
				setLoading(false);
				setPost(data);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	}, [selectedDetails]);

	const filterdDetails = post?.find((postName) => {
		return (
			postName?.name?.toLowerCase() ===
			selectedDetails?.benefitName?.toLowerCase()
		);
	});

	return (
		<SideModal
			btnText={'Choose Benefit'}
			handleClick={() => {
				handleSelectedPlans(selectedDetails);
				setOpenDetailsModal(false);
			}}
			headerText={
				filterdDetails ? filterdDetails.name : selectedDetails?.benefitName
			}
			openSideModal={openDetailsModal}
			setOpenSideModal={setOpenDetailsModal}
			isCart={false}
			btnDisbled={
				plansSelected.includes(selectedDetails?.benefitName) ? true : false
			}
			isBtn={isBtn}
		>
			<>
				{loading ? (
					<>
						<Spinner />
					</>
				) : (
					<>
						{showCardDetails && (
							<div className="plan__status">
								<PlanStatusCard
									isRemider={false}
									numberOfPlan={selectedDetails?.activeEmployeeCount}
									status={'active'}
									statusName={'ACTIVE EMPLOYEES'}
									total={numberOfEmployee}
								/>
								<PlanStatusCard
									isRemider={true}
									numberOfPlan={selectedDetails?.inactiveEmployeeCount}
									status={'inactive'}
									statusName={'Inactive Employees'}
									total={numberOfEmployee}
									remindEmployeeBenefit={() =>
										remindEmployeeBenefit(selectedDetails?.id)
									}
								/>
							</div>
						)}

						{filterdDetails ? (
							<div className="benefit__details">
								<div className="benefit__details--detail">
									<p>{filterdDetails?.bio}</p>
								</div>

								<div className="benefit__details--overview">
									<h2>Overwiew</h2>
									<div className="overview--container">
										<p>Region of Cover</p>
										<p className="country">
											{filterdDetails?.regionCovered[0]}
										</p>
									</div>
								</div>
								<div className="benefit__details--general">
									<h2>General Care</h2>
									{filterdDetails?.outPatientLimitPrice && (
										<div className="outPatient--limit">
											<p>Out-patient limit/annum</p>
											<p>{filterdDetails?.outPatientLimitPrice}</p>
											<p>{filterdDetails?.outPatientLimitDetails}</p>
										</div>
									)}
									{filterdDetails?.inPatientLimitPrice && (
										<div className="inPatient--limit">
											<p>In-patient limit/annum</p>
											<p>{filterdDetails?.inPatientLimitPrice}</p>
											<p>{filterdDetails?.inPatientLimitDetails}</p>
										</div>
									)}
								</div>
								<div className="benefit__details--services">
									{filterdDetails?.allServices?.map((services, index) => (
										<React.Fragment key={index}>
											<h2>{services?.services}</h2>
											{services?.subService?.map((info, index) => (
												<div className="services--container" key={index}>
													<div className="service--type">
														<p>{info.serviceType}</p>
														<span>{info.accessible}</span>
													</div>
													<div className="covered">
														<p>{info.covered}</p>
													</div>
												</div>
											))}
										</React.Fragment>
									))}
								</div>
								<div className="benefit__details--exclusions">
									<h2>GENERAL EXCLUSIONS</h2>

									<ul>
										{filterdDetails?.generalExclusions?.map(
											(exclusions, index) => (
												<li key={index}>{exclusions} .</li>
											)
										)}
									</ul>
								</div>
								<div className="benefit__details--notes">
									<div className="notes__container">
										<div className="notes__container--header">
											<Icon id="tip" width="20" height="16" />
											<p>Note the following:</p>
										</div>

										<ul>
											{filterdDetails?.importantNotes?.map(
												(exclusions, index) => (
													<li key={index}>{exclusions} .</li>
												)
											)}
										</ul>
									</div>
								</div>
							</div>
						) : (
							<>
								<p>No description for this benefit yet</p>
							</>
						)}
					</>
				)}
			</>
		</SideModal>
	);
};

export default HealthDetails;
