import React from 'react';
import { Modal } from '../Modal';
import Input from '../forms/Input';
import Button from '../Button';

const EmployeeActionModal = ({
	title,
	show,
	setShow,
	mainText,
	confirmText,
	inputValue,
	setInputValue,
	inputName,
	btnText,
}) => {
	return (
		<Modal title={title} show={show} handleClose={setShow}>
			<div className="modal__content">
				<p className="main-text">{mainText}</p>
				<p className="confirmation">{confirmText}</p>
				<Input
					type="number"
					id={inputValue}
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
					// onBlur={handleBlur}
					label={inputName}
					name={inputName}
				/>

				<Button
					variant="danger"
					className="btn--block"
					onClick={setShow}
					// disabled={loader}
				>
					{/* {loader ? 'Sending...' : 'Resend email'} */}
					{btnText}
				</Button>
			</div>
		</Modal>
	);
};

export default EmployeeActionModal;
